import * as i0 from '@angular/core';
import { Injectable, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = (a0, a1, a2, a3, a4, a5) => ({
  "alert-success": a0,
  "alert-info": a1,
  "alert-danger": a2,
  "alert-warning": a3,
  "alert-none": a4,
  "alert-dismissible": a5
});
const _c1 = a0 => ({
  $implicit: a0
});
function UserNotificationComponent_div_0_div_1_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 9);
  }
  if (rf & 2) {
    const alert_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("innerHtml", alert_r1.message, i0.ɵɵsanitizeHtml);
  }
}
function UserNotificationComponent_div_0_div_1_3_ng_template_0_Template(rf, ctx) {}
function UserNotificationComponent_div_0_div_1_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, UserNotificationComponent_div_0_div_1_3_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const alert_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngTemplateOutlet", alert_r1.messageTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c1, alert_r1));
  }
}
function UserNotificationComponent_div_0_div_1_button_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 11);
    i0.ɵɵlistener("click", function UserNotificationComponent_div_0_div_1_button_4_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const alert_r1 = i0.ɵɵnextContext().$implicit;
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.userNotificationService.removeAlert(alert_r1));
    });
    i0.ɵɵelementEnd();
  }
}
function UserNotificationComponent_div_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4)(1, "div", 5);
    i0.ɵɵtemplate(2, UserNotificationComponent_div_0_div_1_span_2_Template, 1, 1, "span", 6)(3, UserNotificationComponent_div_0_div_1_3_Template, 1, 4, null, 7);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, UserNotificationComponent_div_0_div_1_button_4_Template, 1, 0, "button", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const alert_r1 = ctx.$implicit;
    i0.ɵɵproperty("@flyInOut", undefined)("ngClass", i0.ɵɵpureFunction6(5, _c0, alert_r1.severity === "SUCCESS", alert_r1.severity === "INFO", alert_r1.severity === "DANGER", alert_r1.severity === "WARNING", alert_r1.severity === "NONE", alert_r1.dismissible));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", alert_r1.message);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", alert_r1.messageTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", alert_r1.dismissible);
  }
}
function UserNotificationComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, UserNotificationComponent_div_0_div_1_Template, 5, 12, "div", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const activeAlerts_r4 = ctx.ngIf;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", activeAlerts_r4);
  }
}
function UserNotificationComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 12);
  }
}
class UserNotificationService {
  constructor() {
    this.activeAlerts$ = new BehaviorSubject([]);
    this.activeAlerts = [];
  }
  addAlert(alert) {
    this.activeAlerts = [...this.activeAlerts, alert];
    this.activeAlerts$.next(this.activeAlerts);
    if (alert.autoDismiss) {
      setTimeout(() => this.removeAlert(alert), alert.autoDismiss);
    }
  }
  removeAlert(alert) {
    const index = this.activeAlerts.indexOf(alert);
    if (index !== -1) {
      this.activeAlerts.splice(index, 1);
      this.activeAlerts$.next(this.activeAlerts);
      return true;
    }
    return false;
  }
  static {
    this.ɵfac = function UserNotificationService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UserNotificationService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserNotificationService,
      factory: UserNotificationService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserNotificationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class UserNotificationComponent {
  constructor(userNotificationService) {
    this.userNotificationService = userNotificationService;
    this.blocking = false;
    this.subscription = this.userNotificationService.activeAlerts$.subscribe(alerts => {
      this.blocking = alerts.filter(a => a.blocking).length > 0;
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  static {
    this.ɵfac = function UserNotificationComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UserNotificationComponent)(i0.ɵɵdirectiveInject(UserNotificationService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: UserNotificationComponent,
      selectors: [["aviatar-user-notification"]],
      standalone: false,
      decls: 3,
      vars: 4,
      consts: [["class", "notification-wrapper", 4, "ngIf"], ["class", "modal-backdrop", 4, "ngIf"], [1, "notification-wrapper"], ["class", "alert show", "role", "alert", 3, "ngClass", 4, "ngFor", "ngForOf"], ["role", "alert", 1, "alert", "show", 3, "ngClass"], [1, "alert-content"], [3, "innerHtml", 4, "ngIf"], [4, "ngIf"], ["type", "button", "class", "btn-close", 3, "click", 4, "ngIf"], [3, "innerHtml"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], ["type", "button", 1, "btn-close", 3, "click"], [1, "modal-backdrop"]],
      template: function UserNotificationComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, UserNotificationComponent_div_0_Template, 2, 1, "div", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵtemplate(2, UserNotificationComponent_div_2_Template, 1, 0, "div", 1);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 2, ctx.userNotificationService.activeAlerts$));
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.blocking);
        }
      },
      dependencies: [i2.NgClass, i2.NgForOf, i2.NgIf, i2.NgTemplateOutlet, i2.AsyncPipe],
      styles: ["[_nghost-%COMP%]{display:flex;justify-content:center}.notification-wrapper[_ngcontent-%COMP%]{position:absolute;z-index:1081;width:700px;max-width:100%}.alert[_ngcontent-%COMP%]{margin-bottom:2px;width:100%;overflow:hidden;box-shadow:0 1px 2px #0000001a}.alert.alert-none[_ngcontent-%COMP%]{background-color:#fff}.alert[_ngcontent-%COMP%]   .btn[_ngcontent-%COMP%]{position:absolute;top:0;right:0}.modal-backdrop[_ngcontent-%COMP%]{opacity:.5;z-index:-2}"],
      data: {
        animation: [trigger('flyInOut', [state('in', style({
          transform: 'translateX(0)',
          opacity: 1,
          height: '*'
        })), transition('void => *', [style({
          transform: 'translateY(-100%)',
          opacity: 0,
          height: 0
        }), animate('100ms cubic-bezier(0.600, 0.040, 0.980, 0.335)')]), transition('* => void', [animate('100ms cubic-bezier(0.600, 0.040, 0.980, 0.335)', style({
          transform: 'translateY(-100%)',
          opacity: 0,
          height: 0
        }))])])]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserNotificationComponent, [{
    type: Component,
    args: [{
      selector: 'aviatar-user-notification',
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [trigger('flyInOut', [state('in', style({
        transform: 'translateX(0)',
        opacity: 1,
        height: '*'
      })), transition('void => *', [style({
        transform: 'translateY(-100%)',
        opacity: 0,
        height: 0
      }), animate('100ms cubic-bezier(0.600, 0.040, 0.980, 0.335)')]), transition('* => void', [animate('100ms cubic-bezier(0.600, 0.040, 0.980, 0.335)', style({
        transform: 'translateY(-100%)',
        opacity: 0,
        height: 0
      }))])])],
      standalone: false,
      template: "<div class=\"notification-wrapper\" *ngIf=\"userNotificationService.activeAlerts$ | async as activeAlerts\">\n\n  <div class=\"alert show\"\n       [@flyInOut]\n       role=\"alert\"\n       [ngClass]=\"{\n         'alert-success': alert.severity === 'SUCCESS',\n         'alert-info': alert.severity === 'INFO',\n         'alert-danger': alert.severity === 'DANGER',\n         'alert-warning': alert.severity === 'WARNING',\n         'alert-none': alert.severity === 'NONE',\n\n         'alert-dismissible': alert.dismissible\n       }\"\n       *ngFor=\"let alert of activeAlerts\">\n\n    <div class=\"alert-content\">\n      <span [innerHtml]=\"alert.message\" *ngIf=\"alert.message\"></span>\n      <ng-template *ngIf=\"alert.messageTemplate\"\n                   [ngTemplateOutlet]=\"alert.messageTemplate\"\n                   [ngTemplateOutletContext]=\" { $implicit: alert }\"></ng-template>\n    </div>\n\n    <button type=\"button\" class=\"btn-close\" (click)=\"userNotificationService.removeAlert(alert)\" *ngIf=\"alert.dismissible\"></button>\n  </div>\n\n\n</div>\n\n<div class=\"modal-backdrop\" *ngIf=\"blocking\"></div>\n",
      styles: [":host{display:flex;justify-content:center}.notification-wrapper{position:absolute;z-index:1081;width:700px;max-width:100%}.alert{margin-bottom:2px;width:100%;overflow:hidden;box-shadow:0 1px 2px #0000001a}.alert.alert-none{background-color:#fff}.alert .btn{position:absolute;top:0;right:0}.modal-backdrop{opacity:.5;z-index:-2}\n"]
    }]
  }], () => [{
    type: UserNotificationService
  }], null);
})();
var Severity;
(function (Severity) {
  Severity["DANGER"] = "DANGER";
  Severity["WARNING"] = "WARNING";
  Severity["INFO"] = "INFO";
  Severity["SUCCESS"] = "SUCCESS";
  Severity["NONE"] = "NONE";
})(Severity || (Severity = {}));
class Alert {
  constructor(message, severity, dismissible, autoDismiss, blocking) {
    this.message = message;
    this.severity = severity;
    this.dismissible = dismissible;
    this.autoDismiss = autoDismiss;
    this.blocking = blocking;
  }
}
class TemplateAlert extends Alert {
  constructor(messageTemplate, severity, dismissible, autoDismiss, blocking) {
    super(null, severity, dismissible, autoDismiss, blocking);
    this.messageTemplate = messageTemplate;
    this.severity = severity;
    this.dismissible = dismissible;
    this.autoDismiss = autoDismiss;
    this.blocking = blocking;
  }
}
class UserNotificationModule {
  static {
    this.ɵfac = function UserNotificationModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UserNotificationModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: UserNotificationModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserNotificationModule, [{
    type: NgModule,
    args: [{
      declarations: [UserNotificationComponent],
      imports: [CommonModule],
      exports: [UserNotificationComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of user-notification
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Alert, Severity, TemplateAlert, UserNotificationComponent, UserNotificationModule, UserNotificationService };
