import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TagMapperService, TagVisualization } from '@app/core/services/tag-mapper.service';

export interface Tag {
  type: string;
  value: string;
  label?: string;
}

@Component({
  selector: 'cc-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TagComponent implements OnInit {

  @Input()
  tag: Tag;

  constructor(private tagMapper: TagMapperService) {
  }

  ngOnInit(): void {
  }

  getTagVisualization(tag: Tag): Observable<TagVisualization> {
    return this.tagMapper.getVisualizationAsync(tag.type);
  }

}
