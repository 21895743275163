import { AfterContentInit, Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ResizingEventArgs, SplitterComponent } from '@syncfusion/ej2-angular-layouts';

const BREAKPOINT = 576;
const DEFAULT_SIDEBAR_SIZE = '25%';
const DEFAULT_SIDEBAR_NAME = 'sidebar';
const SIDEBAR_WIDTH_STOAGE_PREFIX = 'sidebar-width';

@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SidebarLayoutComponent implements OnInit, AfterContentInit {

  lastWindowWidth: number = BREAKPOINT;
  sidebarVisible = true;

  @Input()
  defaultSidebarSize?: string = DEFAULT_SIDEBAR_SIZE;
  @Input()
  name?: string = DEFAULT_SIDEBAR_NAME;

  @ViewChild('horizontal', { static: true })
  public splitterComponent: SplitterComponent;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.lastWindowWidth < BREAKPOINT) {
        this.closeSidebar();
      }
    });

    // restore user defined sidebar size
    if (localStorage.getItem(this.getSidebarWidthStorageKey())) {
      this.defaultSidebarSize = localStorage.getItem(this.getSidebarWidthStorageKey());
    }

    // persist user defined sidebar size
    if (this.splitterComponent && this.splitterComponent.resizeStop) {
      this.splitterComponent.resizeStop.subscribe((event: ResizingEventArgs) => {
        localStorage.setItem(this.getSidebarWidthStorageKey(), event.paneSize[0] + 'px');
      });
    }
  }

  ngAfterContentInit() {
    if (window.innerWidth <= BREAKPOINT) {
      this.closeSidebar();
    }
  }

  toggleSidebar(): void {
    if (this.sidebarVisible) {
      this.closeSidebar();
    } else {
      this.openSidebar();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < BREAKPOINT && this.lastWindowWidth >= BREAKPOINT) {
      this.closeSidebar();
    }
    this.lastWindowWidth = event.target.innerWidth;
  }

  private closeSidebar() {
    this.sidebarVisible = false;
    this.splitterComponent.collapse(0);
  }

  private openSidebar() {
    this.sidebarVisible = true;
    this.splitterComponent.expand(0);
  }

  private getSidebarWidthStorageKey(): string {
    return SIDEBAR_WIDTH_STOAGE_PREFIX + '_' + this.name;
  }

}
