import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Observable } from 'rxjs';
import { delay, filter, take } from 'rxjs/operators';

@Directive({
  selector: '[ccInfiniteScrollTrigger]',
  standalone: false
})
export class InfiniteScrollTriggerDirective implements OnInit {

  @Input()
  initialized: Observable<boolean>;

  constructor(
    private elementRef: ElementRef,
    private infiniteScroll: InfiniteScrollDirective
  ) {
  }

  ngOnInit(): void {
    this.initialized.pipe(
      filter(isInitialized => !!isInitialized),
      take(1),
      delay(100), // add a delay as elements are not added fast enough to view so container has 0 height
      filter(() => this.doesContainerOverflow())
    ).subscribe(() => this.infiniteScroll.scrolled.emit()); // trigger ngx-infinite-scroll to load next page
  }

  private doesContainerOverflow() {
    const container = this.resolveContainerElement(
      this.infiniteScroll.infiniteScrollContainer,
      this.infiniteScroll.scrollWindow,
      this.elementRef.nativeElement,
      this.infiniteScroll.scrollWindow
    );
    if (!container) {
      return false;
    }
    return container.clientHeight === container.scrollHeight;
  }

  private resolveContainerElement(
    selector: string | any,
    scrollWindow,
    defaultElement,
    fromRoot: boolean
  ): any {
    const hasWindow = window && !!window.document && window.document.documentElement;
    let container = hasWindow && scrollWindow ? window : defaultElement;
    if (selector) {
      const containerIsString =
        selector && hasWindow && typeof selector === 'string';
      container = containerIsString
        ? this.findElement(selector, defaultElement.nativeElement, fromRoot)
        : selector;
      if (!container) {
        throw new Error('ngx-infinite-scroll {resolveContainerElement()}: selector for');
      }
    }
    return container;
  }

  private findElement(
    selector: string | any,
    customRoot: ElementRef | any,
    fromRoot: boolean
  ) {
    const rootEl = fromRoot ? window.document : customRoot;
    return rootEl.querySelector(selector);
  }

}
