import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, ContentChild, ContentChildren, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ComplexBase, setValue, Template, ArrayBase, ComponentBase, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { Splitter, DashboardLayout } from '@syncfusion/ej2-layouts';
const _c0 = ["content"];
const _c1 = [[["div"]]];
const _c2 = ["div"];
const _c3 = ["header"];
export * from '@syncfusion/ej2-layouts';
import { CommonModule } from '@angular/common';
let input$1 = ['collapsed', 'collapsible', 'content', 'cssClass', 'max', 'min', 'resizable', 'size'];
let outputs$3 = [];
/**
 * 'e-panesettings' directive represent a panes of angular splitter
 * It must be contained in a Splitter component(`ejs-splitter`).
 * ```html
 * <ejs-splitter id='splitter' >
 *   <e-panes>
 *    <e-pane size ='150px'></e-pane>
 *    <e-pane size = '20%'></e-pane>
 *   </e-panes>
 * </ejs-splitter>
 * ```
 */
class PaneDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$3);
    this.directivePropList = input$1;
  }
}
PaneDirective.ɵfac = function PaneDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PaneDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
PaneDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PaneDirective,
  selectors: [["e-pane"]],
  contentQueries: function PaneDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
    }
  },
  inputs: {
    collapsed: "collapsed",
    collapsible: "collapsible",
    content: "content",
    cssClass: "cssClass",
    max: "max",
    min: "min",
    resizable: "resizable",
    size: "size"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], PaneDirective.prototype, "content", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PaneDirective, [{
    type: Directive,
    args: [{
      selector: 'e-panes>e-pane',
      inputs: input$1,
      outputs: outputs$3,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    content: [{
      type: ContentChild,
      args: ['content']
    }]
  });
})();
/**
 * Pane Array Directive
 * @private
 */
class PanesDirective extends ArrayBase {
  constructor() {
    super('panesettings');
  }
}
PanesDirective.ɵfac = function PanesDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PanesDirective)();
};
PanesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PanesDirective,
  selectors: [["e-panes"]],
  contentQueries: function PanesDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PaneDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PanesDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-splitter>e-panes',
      queries: {
        children: new ContentChildren(PaneDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$1 = ['cssClass', 'enableHtmlSanitizer', 'enablePersistence', 'enableReversePanes', 'enableRtl', 'enabled', 'height', 'locale', 'orientation', 'paneSettings', 'separatorSize', 'width'];
const outputs$2 = ['beforeCollapse', 'beforeExpand', 'beforeSanitizeHtml', 'collapsed', 'created', 'expanded', 'resizeStart', 'resizeStop', 'resizing'];
const twoWays$1 = [''];
/**
 * Represents the Angular Splitter Component
 * ```html
 * <ejs-splitter></ejs-splitter>
 * ```
 */
let SplitterComponent = class SplitterComponent extends Splitter {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['paneSettings'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$2);
    this.addTwoWay.call(this, twoWays$1);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childPaneSettings;
    this.containerContext.ngAfterContentChecked(this);
  }
};
SplitterComponent.ɵfac = function SplitterComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SplitterComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
SplitterComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SplitterComponent,
  selectors: [["ejs-splitter"]],
  contentQueries: function SplitterComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PanesDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childPaneSettings = _t.first);
    }
  },
  inputs: {
    cssClass: "cssClass",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableReversePanes: "enableReversePanes",
    enableRtl: "enableRtl",
    enabled: "enabled",
    height: "height",
    locale: "locale",
    orientation: "orientation",
    paneSettings: "paneSettings",
    separatorSize: "separatorSize",
    width: "width"
  },
  outputs: {
    beforeCollapse: "beforeCollapse",
    beforeExpand: "beforeExpand",
    beforeSanitizeHtml: "beforeSanitizeHtml",
    collapsed: "collapsed",
    created: "created",
    expanded: "expanded",
    resizeStart: "resizeStart",
    resizeStop: "resizeStop",
    resizing: "resizing"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c2,
  decls: 1,
  vars: 0,
  template: function SplitterComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c1);
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
SplitterComponent = __decorate([ComponentMixins([ComponentBase])], SplitterComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SplitterComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-splitter',
      inputs: inputs$1,
      outputs: outputs$2,
      template: `<ng-content select='div'></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childPaneSettings: new ContentChild(PanesDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the Splitter component.
 */
class SplitterModule {}
SplitterModule.ɵfac = function SplitterModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SplitterModule)();
};
SplitterModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SplitterModule
});
SplitterModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SplitterModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [SplitterComponent, PaneDirective, PanesDirective],
      exports: [SplitterComponent, PaneDirective, PanesDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Splitter component with providers.
 */
class SplitterAllModule {}
SplitterAllModule.ɵfac = function SplitterAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SplitterAllModule)();
};
SplitterAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SplitterAllModule
});
SplitterAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, SplitterModule], SplitterModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SplitterAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SplitterModule],
      exports: [SplitterModule],
      providers: []
    }]
  }], null, null);
})();
let input = ['col', 'content', 'cssClass', 'enabled', 'header', 'id', 'maxSizeX', 'maxSizeY', 'minSizeX', 'minSizeY', 'row', 'sizeX', 'sizeY', 'zIndex'];
let outputs$1 = [];
/**
 * 'e-panels' directive represent a panels of angular dashboardlayout
 * It must be contained in a dashboardlayout component(`ej-dashboardlayout`).
 * ```html
 * <ejs-dashboardlayout>
 *   <e-panels>
 *    <e-panel></e-panel>
 *    <e-panel></e-panel>
 *   </e-panels>
 * </ejs-dashboardlayout>
 * ```
 */
class PanelDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$1);
    this.directivePropList = input;
  }
}
PanelDirective.ɵfac = function PanelDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PanelDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
PanelDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PanelDirective,
  selectors: [["e-panel"]],
  contentQueries: function PanelDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.header = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
    }
  },
  inputs: {
    col: "col",
    content: "content",
    cssClass: "cssClass",
    enabled: "enabled",
    header: "header",
    id: "id",
    maxSizeX: "maxSizeX",
    maxSizeY: "maxSizeY",
    minSizeX: "minSizeX",
    minSizeY: "minSizeY",
    row: "row",
    sizeX: "sizeX",
    sizeY: "sizeY",
    zIndex: "zIndex"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], PanelDirective.prototype, "header", void 0);
__decorate([Template()], PanelDirective.prototype, "content", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PanelDirective, [{
    type: Directive,
    args: [{
      selector: 'e-panels>e-panel',
      inputs: input,
      outputs: outputs$1,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    header: [{
      type: ContentChild,
      args: ['header']
    }],
    content: [{
      type: ContentChild,
      args: ['content']
    }]
  });
})();
/**
 * Panel Array Directive
 * @private
 */
class PanelsDirective extends ArrayBase {
  constructor() {
    super('panels');
  }
}
PanelsDirective.ɵfac = function PanelsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PanelsDirective)();
};
PanelsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PanelsDirective,
  selectors: [["e-panels"]],
  contentQueries: function PanelsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PanelDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PanelsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-dashboardlayout>e-panels',
      queries: {
        children: new ContentChildren(PanelDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs = ['allowDragging', 'allowFloating', 'allowPushing', 'allowResizing', 'cellAspectRatio', 'cellSpacing', 'columns', 'draggableHandle', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'locale', 'mediaQuery', 'panels', 'resizableHandles', 'showGridLines'];
const outputs = ['change', 'created', 'destroyed', 'drag', 'dragStart', 'dragStop', 'resize', 'resizeStart', 'resizeStop'];
const twoWays = [''];
/**
 * Represents the Essential JS 2 Angular DashboardLayout Component.
 * ```html
 * <ejs-dashboardlayout></ejs-dashboardlayout>
 * ```
 */
let DashboardLayoutComponent = class DashboardLayoutComponent extends DashboardLayout {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['panels'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childPanels;
    this.containerContext.ngAfterContentChecked(this);
  }
};
DashboardLayoutComponent.ɵfac = function DashboardLayoutComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DashboardLayoutComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
DashboardLayoutComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DashboardLayoutComponent,
  selectors: [["ejs-dashboardlayout"]],
  contentQueries: function DashboardLayoutComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PanelsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childPanels = _t.first);
    }
  },
  inputs: {
    allowDragging: "allowDragging",
    allowFloating: "allowFloating",
    allowPushing: "allowPushing",
    allowResizing: "allowResizing",
    cellAspectRatio: "cellAspectRatio",
    cellSpacing: "cellSpacing",
    columns: "columns",
    draggableHandle: "draggableHandle",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    locale: "locale",
    mediaQuery: "mediaQuery",
    panels: "panels",
    resizableHandles: "resizableHandles",
    showGridLines: "showGridLines"
  },
  outputs: {
    change: "change",
    created: "created",
    destroyed: "destroyed",
    drag: "drag",
    dragStart: "dragStart",
    dragStop: "dragStop",
    resize: "resize",
    resizeStart: "resizeStart",
    resizeStop: "resizeStop"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c2,
  decls: 1,
  vars: 0,
  template: function DashboardLayoutComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c1);
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DashboardLayoutComponent = __decorate([ComponentMixins([ComponentBase])], DashboardLayoutComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DashboardLayoutComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-dashboardlayout',
      inputs: inputs,
      outputs: outputs,
      template: `<ng-content select='div'></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childPanels: new ContentChild(PanelsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the DashboardLayout component.
 */
class DashboardLayoutModule {}
DashboardLayoutModule.ɵfac = function DashboardLayoutModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DashboardLayoutModule)();
};
DashboardLayoutModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DashboardLayoutModule
});
DashboardLayoutModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DashboardLayoutModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [DashboardLayoutComponent, PanelDirective, PanelsDirective],
      exports: [DashboardLayoutComponent, PanelDirective, PanelsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the DashboardLayout component with providers.
 */
class DashboardLayoutAllModule {}
DashboardLayoutAllModule.ɵfac = function DashboardLayoutAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DashboardLayoutAllModule)();
};
DashboardLayoutAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DashboardLayoutAllModule
});
DashboardLayoutAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, DashboardLayoutModule], DashboardLayoutModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DashboardLayoutAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, DashboardLayoutModule],
      exports: [DashboardLayoutModule],
      providers: []
    }]
  }], null, null);
})();

// Mapping root file for package generation

/**
 * Generated bundle index. Do not edit.
 */

export { DashboardLayoutAllModule, DashboardLayoutComponent, DashboardLayoutModule, PaneDirective, PanelDirective, PanelsDirective, PanesDirective, SplitterAllModule, SplitterComponent, SplitterModule };
