import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cc-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectInputComponent
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SelectInputComponent,
      multi: true
    }
  ],
  standalone: false
})
export class SelectInputComponent implements ControlValueAccessor {

  modelValue;

  @Input() name: string;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() loading = false;
  @Input() loadingText: string;
  @Input() notFoundText: string;
  @Input() searchFn = null;
  @Input() placeholder: string;
  @Input() multiple: boolean;
  @Input() readonly: boolean;
  @Input() items: any[];
  @Input() clearable = true;
  @Input() closeOnSelect = true;
  @Input() virtualScroll = false;

  @Input()
  get model() {
    return this.modelValue;
  }

  set model(val) {
    this.modelValue = val;
    this.modelChange.emit(this.modelValue);
  }

  @Output()
  changed = new EventEmitter();

  @Output()
  modelChange = new EventEmitter();

  @ViewChild(NgSelectComponent, { static: false })
  select: NgSelectComponent;

  clear() {
    switch (typeof this.model) {
      case 'object':
        this.model = [];
        break;
      case 'string':
        this.model = '';
        break;
      default:
        this.model = null;
    }

    this.changed.emit(this.model);
  }

  onChange = (items) => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  writeValue(obj: any): void {
    this.model = obj;
  }

  validate({ value }: UntypedFormControl) {
    const quantity = this.items.length;
    if (quantity <= 0) {
      return {
        mustBePositive: {
          quantity
        }
      };
    }
  }

}
