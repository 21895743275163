import * as i0 from '@angular/core';
import { Injectable, InjectionToken, Inject, NgModule, Optional, SkipSelf } from '@angular/core';
import * as i2 from '@angular/common/http';
import { HTTP_INTERCEPTORS, HttpParams, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
/**
 * Global configuration
 */
class AviatarApiConfiguration {
  constructor() {
    this.rootUrl = 'https://api-dev.apps.aviatar.io';
  }
  static {
    this.ɵfac = function AviatarApiConfiguration_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AviatarApiConfiguration)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AviatarApiConfiguration,
      factory: AviatarApiConfiguration.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AviatarApiConfiguration, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const AVIATAR_API_CONFIG = new InjectionToken('aviatar-api-config');
class ApiCredentialsInterceptor {
  constructor(aviatarApiConfig) {
    this.aviatarApiConfig = aviatarApiConfig;
  }
  intercept(request, next) {
    // add app_id and app_key to any request that goes to AVIATAR API
    if (request.url.startsWith(this.aviatarApiConfig.baseUrl) && !request.headers['app_id']) {
      request = request.clone({
        setHeaders: {
          app_id: this.aviatarApiConfig.appId,
          app_key: this.aviatarApiConfig.appKey
        }
      });
    }
    return next.handle(request);
  }
  static {
    this.ɵfac = function ApiCredentialsInterceptor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ApiCredentialsInterceptor)(i0.ɵɵinject(AVIATAR_API_CONFIG));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ApiCredentialsInterceptor,
      factory: ApiCredentialsInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiCredentialsInterceptor, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [AVIATAR_API_CONFIG]
    }]
  }], null);
})();
class AviatarApiConfigClass extends AviatarApiConfiguration {
  constructor(aviatarApiConfig) {
    super();
    this.aviatarApiConfig = aviatarApiConfig;
    this.rootUrl = aviatarApiConfig.baseUrl;
  }
  static {
    this.ɵfac = function AviatarApiConfigClass_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AviatarApiConfigClass)(i0.ɵɵinject(AVIATAR_API_CONFIG));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AviatarApiConfigClass,
      factory: AviatarApiConfigClass.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AviatarApiConfigClass, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [AVIATAR_API_CONFIG]
    }]
  }], null);
})();
class AviatarApiClientModule {
  static {
    this.ɵfac = function AviatarApiClientModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AviatarApiClientModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AviatarApiClientModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        // configure rest client base path
        provide: AviatarApiConfiguration,
        useClass: AviatarApiConfigClass
      }, {
        // add api-gateway credentials to http requests
        provide: HTTP_INTERCEPTORS,
        useClass: ApiCredentialsInterceptor,
        multi: true
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AviatarApiClientModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [],
      exports: [],
      providers: [{
        // configure rest client base path
        provide: AviatarApiConfiguration,
        useClass: AviatarApiConfigClass
      }, {
        // add api-gateway credentials to http requests
        provide: HTTP_INTERCEPTORS,
        useClass: ApiCredentialsInterceptor,
        multi: true
      }]
    }]
  }], null, null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
/**
 * Base class for services
 */
class BaseService {
  constructor(config, http) {
    this.config = config;
    this.http = http;
  }
  /**
   * Returns the root url for all operations in this service. If not set directly in this
   * service, will fallback to `AviatarApiConfiguration.rootUrl`.
   */
  get rootUrl() {
    return this._rootUrl || this.config.rootUrl;
  }
  /**
   * Sets the root URL for API operations in this service.
   */
  set rootUrl(rootUrl) {
    this._rootUrl = rootUrl;
  }
  static {
    this.ɵfac = function BaseService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BaseService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BaseService,
      factory: BaseService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseService, [{
    type: Injectable
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
/**
 * Custom parameter codec to correctly handle the plus sign in parameter
 * values. See https://github.com/angular/angular/issues/18261
 */
class ParameterCodec {
  encodeKey(key) {
    return encodeURIComponent(key);
  }
  encodeValue(value) {
    return encodeURIComponent(value);
  }
  decodeKey(key) {
    return decodeURIComponent(key);
  }
  decodeValue(value) {
    return decodeURIComponent(value);
  }
}
const ParameterCodecInstance = new ParameterCodec();
/**
 * Base class for a parameter
 */
class Parameter {
  constructor(name, value, options, defaultStyle, defaultExplode) {
    this.name = name;
    this.value = value;
    this.options = options;
    this.options = options || {};
    if (this.options.style === null || this.options.style === undefined) {
      this.options.style = defaultStyle;
    }
    if (this.options.explode === null || this.options.explode === undefined) {
      this.options.explode = defaultExplode;
    }
  }
  serializeValue(value, separator = ',') {
    if (value === null || value === undefined) {
      return '';
    } else if (value instanceof Array) {
      return value.map(v => this.serializeValue(v).split(separator).join(encodeURIComponent(separator))).join(separator);
    } else if (typeof value === 'object') {
      const array = [];
      for (const key of Object.keys(value)) {
        let propVal = value[key];
        if (propVal !== null && propVal !== undefined) {
          propVal = this.serializeValue(propVal).split(separator).join(encodeURIComponent(separator));
          if (this.options.explode) {
            array.push(`${key}=${propVal}`);
          } else {
            array.push(key);
            array.push(propVal);
          }
        }
      }
      return array.join(separator);
    } else {
      return String(value);
    }
  }
}
/**
 * A parameter in the operation path
 */
class PathParameter extends Parameter {
  constructor(name, value, options) {
    super(name, value, options, 'simple', false);
  }
  append(path) {
    let value = this.value;
    if (value === null || value === undefined) {
      value = '';
    }
    let prefix = this.options.style === 'label' ? '.' : '';
    let separator = this.options.explode ? prefix === '' ? ',' : prefix : ',';
    let alreadySerialized = false;
    if (this.options.style === 'matrix') {
      // The parameter name is just used as prefix, except in some cases...
      prefix = `;${this.name}=`;
      if (this.options.explode && typeof value === 'object') {
        prefix = ';';
        if (value instanceof Array) {
          // For arrays we have to repeat the name for each element
          value = value.map(v => `${this.name}=${this.serializeValue(v, ';')}`);
          value = value.join(';');
          alreadySerialized = true;
        } else {
          // For objects we have to put each the key / value pairs
          value = this.serializeValue(value, ';');
          alreadySerialized = true;
        }
      }
    }
    value = prefix + (alreadySerialized ? value : this.serializeValue(value, separator));
    // Replace both the plain variable and the corresponding variant taking in the prefix and explode into account
    path = path.replace(`{${this.name}}`, value);
    path = path.replace(`{${prefix}${this.name}${this.options.explode ? '*' : ''}}`, value);
    return path;
  }
  // @ts-ignore
  serializeValue(value, separator = ',') {
    var result = typeof value === 'string' ? encodeURIComponent(value) : super.serializeValue(value, separator);
    result = result.replace(/%3D/g, '=');
    result = result.replace(/%3B/g, ';');
    result = result.replace(/%2C/g, ',');
    return result;
  }
}
/**
 * A parameter in the query
 */
class QueryParameter extends Parameter {
  constructor(name, value, options) {
    super(name, value, options, 'form', true);
  }
  append(params) {
    if (this.value instanceof Array) {
      // Array serialization
      if (this.options.explode) {
        for (const v of this.value) {
          params = params.append(this.name, this.serializeValue(v));
        }
      } else {
        const separator = this.options.style === 'spaceDelimited' ? ' ' : this.options.style === 'pipeDelimited' ? '|' : ',';
        return params.append(this.name, this.serializeValue(this.value, separator));
      }
    } else if (this.value !== null && typeof this.value === 'object') {
      // Object serialization
      if (this.options.style === 'deepObject') {
        // Append a parameter for each key, in the form `name[key]`
        for (const key of Object.keys(this.value)) {
          const propVal = this.value[key];
          if (propVal !== null && propVal !== undefined) {
            params = params.append(`${this.name}[${key}]`, this.serializeValue(propVal));
          }
        }
      } else if (this.options.explode) {
        // Append a parameter for each key without using the parameter name
        for (const key of Object.keys(this.value)) {
          const propVal = this.value[key];
          if (propVal !== null && propVal !== undefined) {
            params = params.append(key, this.serializeValue(propVal));
          }
        }
      } else {
        // Append a single parameter whose values are a comma-separated list of key,value,key,value...
        const array = [];
        for (const key of Object.keys(this.value)) {
          const propVal = this.value[key];
          if (propVal !== null && propVal !== undefined) {
            array.push(key);
            array.push(propVal);
          }
        }
        params = params.append(this.name, this.serializeValue(array));
      }
    } else if (this.value !== null && this.value !== undefined) {
      // Plain value
      params = params.append(this.name, this.serializeValue(this.value));
    }
    return params;
  }
}
/**
 * A parameter in the HTTP request header
 */
class HeaderParameter extends Parameter {
  constructor(name, value, options) {
    super(name, value, options, 'simple', false);
  }
  append(headers) {
    if (this.value !== null && this.value !== undefined) {
      if (this.value instanceof Array) {
        for (const v of this.value) {
          headers = headers.append(this.name, this.serializeValue(v));
        }
      } else {
        headers = headers.append(this.name, this.serializeValue(this.value));
      }
    }
    return headers;
  }
}
/**
 * Helper to build http requests from parameters
 */
class RequestBuilder {
  constructor(rootUrl, operationPath, method) {
    this.rootUrl = rootUrl;
    this.operationPath = operationPath;
    this.method = method;
    this._path = new Map();
    this._query = new Map();
    this._header = new Map();
  }
  /**
   * Sets a path parameter
   */
  path(name, value, options) {
    this._path.set(name, new PathParameter(name, value, options || {}));
  }
  /**
   * Sets a query parameter
   */
  query(name, value, options) {
    this._query.set(name, new QueryParameter(name, value, options || {}));
  }
  /**
   * Sets a header parameter
   */
  header(name, value, options) {
    this._header.set(name, new HeaderParameter(name, value, options || {}));
  }
  /**
   * Sets the body content, along with the content type
   */
  body(value, contentType = 'application/json') {
    if (value instanceof Blob) {
      this._bodyContentType = value.type;
    } else {
      this._bodyContentType = contentType;
    }
    if (this._bodyContentType === 'application/x-www-form-urlencoded' && value !== null && typeof value === 'object') {
      // Handle URL-encoded data
      const pairs = [];
      for (const key of Object.keys(value)) {
        let val = value[key];
        if (!(val instanceof Array)) {
          val = [val];
        }
        for (const v of val) {
          const formValue = this.formDataValue(v);
          if (formValue !== null) {
            pairs.push([key, formValue]);
          }
        }
      }
      this._bodyContent = pairs.map(p => `${encodeURIComponent(p[0])}=${encodeURIComponent(p[1])}`).join('&');
    } else if (this._bodyContentType === 'multipart/form-data') {
      // Handle multipart form data
      const formData = new FormData();
      if (value !== null && value !== undefined) {
        for (const key of Object.keys(value)) {
          const val = value[key];
          if (val instanceof Array) {
            for (const v of val) {
              const toAppend = this.formDataValue(v);
              if (toAppend !== null) {
                formData.append(key, toAppend);
              }
            }
          } else {
            const toAppend = this.formDataValue(val);
            if (toAppend !== null) {
              formData.set(key, toAppend);
            }
          }
        }
      }
      this._bodyContent = formData;
    } else {
      // The body is the plain content
      this._bodyContent = value;
    }
  }
  formDataValue(value) {
    if (value === null || value === undefined) {
      return null;
    }
    if (value instanceof Blob) {
      return value;
    }
    if (typeof value === 'object') {
      return new Blob([JSON.stringify(value)], {
        type: 'application/json'
      });
    }
    return String(value);
  }
  /**
   * Builds the request with the current set parameters
   */
  build(options) {
    options = options || {};
    // Path parameters
    let path = this.operationPath;
    for (const pathParam of this._path.values()) {
      path = pathParam.append(path);
    }
    const url = this.rootUrl + path;
    // Query parameters
    let httpParams = new HttpParams({
      encoder: ParameterCodecInstance
    });
    for (const queryParam of this._query.values()) {
      httpParams = queryParam.append(httpParams);
    }
    // Header parameters
    let httpHeaders = new HttpHeaders();
    if (options.accept) {
      httpHeaders = httpHeaders.append('Accept', options.accept);
    }
    for (const headerParam of this._header.values()) {
      httpHeaders = headerParam.append(httpHeaders);
    }
    // Request content headers
    if (this._bodyContentType && !(this._bodyContent instanceof FormData)) {
      httpHeaders = httpHeaders.set('Content-Type', this._bodyContentType);
    }
    // Perform the request
    return new HttpRequest(this.method.toUpperCase(), url, this._bodyContent, {
      params: httpParams,
      headers: httpHeaders,
      responseType: options.responseType,
      reportProgress: options.reportProgress,
      context: options.context
    });
  }
}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAircraft(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAircraft.PATH, 'get');
  if (params) {
    rb.query('operator', params.operator, {});
    rb.query('partnerId', params.partnerId, {});
    rb.query('msn', params.msn, {});
    rb.query('id', params.id, {});
    rb.query('labelType', params.labelType, {});
    rb.query('includeCounters', params.includeCounters, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAircraft.PATH = '/aircraft';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAircraftBaseData(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAircraftBaseData.PATH, 'get');
  if (params) {
    rb.query('id', params.id, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAircraftBaseData.PATH = '/aircraft/base-data';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAircraftById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAircraftById.PATH, 'get');
  if (params) {
    rb.path('aircraftId', params.aircraftId, {});
    rb.query('labelType', params.labelType, {});
    rb.query('includeCounters', params.includeCounters, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAircraftById.PATH = '/aircraft/{aircraftId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAircraftForIcaoCodes(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAircraftForIcaoCodes.PATH, 'get');
  if (params) {
    rb.query('icao', params.icao, {});
    rb.query('labelType', params.labelType, {});
    rb.query('includeCounters', params.includeCounters, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAircraftForIcaoCodes.PATH = '/aircraft-by-icao';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAircraftHistory(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAircraftHistory.PATH, 'get');
  if (params) {
    rb.path('aircraftId', params.aircraftId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAircraftHistory.PATH = '/aircraft/history/{aircraftId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAircraftIdByAcreg(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAircraftIdByAcreg.PATH, 'get');
  if (params) {
    rb.query('acreg', params.acreg, {});
    rb.query('date', params.date, {});
    rb.query('partnerId', params.partnerId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAircraftIdByAcreg.PATH = '/aircraftid-by-acreg';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAircraftIdByAcregPart(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAircraftIdByAcregPart.PATH, 'get');
  if (params) {
    rb.query('acreg', params.acreg, {});
    rb.query('date', params.date, {});
    rb.query('partnerId', params.partnerId, {});
    rb.query('codeIcao', params.codeIcao, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAircraftIdByAcregPart.PATH = '/aircraftid-by-partial-acreg';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAircraftPartner(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAircraftPartner.PATH, 'get');
  if (params) {
    rb.path('partnerId', params.partnerId, {});
    rb.query('operator', params.operator, {});
    rb.query('family', params.family, {});
    rb.query('type', params.type, {});
    rb.query('series', params.series, {});
    rb.query('model', params.model, {});
    rb.query('acreg', params.acreg, {});
    rb.query('label', params.label, {});
    rb.query('includeCounters', params.includeCounters, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAircraftPartner.PATH = '/partner/{partnerId}/aircraft/current';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAllAircraftPartner(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAllAircraftPartner.PATH, 'get');
  if (params) {
    rb.path('partnerId', params.partnerId, {});
    rb.query('operator', params.operator, {});
    rb.query('family', params.family, {});
    rb.query('type', params.type, {});
    rb.query('series', params.series, {});
    rb.query('model', params.model, {});
    rb.query('acreg', params.acreg, {});
    rb.query('label', params.label, {});
    rb.query('status', params.status, {});
    rb.query('includeCounters', params.includeCounters, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAllAircraftPartner.PATH = '/partner/{partnerId}/aircraft/all';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getResolveAircraftIdOverTime(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getResolveAircraftIdOverTime.PATH, 'get');
  if (params) {
    rb.query('registrations', params.registrations, {});
    rb.query('partnerId', params.partnerId, {});
    rb.query('dateFrom', params.dateFrom, {});
    rb.query('dateTo', params.dateTo, {});
    rb.query('updateAfter', params.updateAfter, {});
    rb.query('exactMatch', params.exactMatch, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getResolveAircraftIdOverTime.PATH = '/resolve-aircraftid-over-time';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getSamePhysicalAircraft(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getSamePhysicalAircraft.PATH, 'get');
  if (params) {
    rb.path('aircraftId', params.aircraftId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getSamePhysicalAircraft.PATH = '/aircraft/same-physical/{aircraftId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function setAircraftLabels(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, setAircraftLabels.PATH, 'put');
  if (params) {
    rb.path('aircraftId', params.aircraftId, {});
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
setAircraftLabels.PATH = '/aircraft/labels/{aircraftId}';

/**
 * Basic information about aircraft masterdata.
 */
class AircraftService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getAircraft()` */
  static {
    this.GetAircraftPath = '/aircraft';
  }
  /**
   * Returns all aircraft matching criteria.
   *
   * Returns all active aircraft visible for the user matching filter criteria. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId. Do not use for Backend-Only-Clients like Parsers! Use /aircraft/base-data instead there!
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAircraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraft$Response(params, context) {
    return getAircraft(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all aircraft matching criteria.
   *
   * Returns all active aircraft visible for the user matching filter criteria. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId. Do not use for Backend-Only-Clients like Parsers! Use /aircraft/base-data instead there!
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAircraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraft(params, context) {
    return this.getAircraft$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getAircraftForIcaoCodes()` */
  static {
    this.GetAircraftForIcaoCodesPath = '/aircraft-by-icao';
  }
  /**
   * Returns all aircraft matching icao.
   *
   * Returns all aircraft for the icao codes provided. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAircraftForIcaoCodes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftForIcaoCodes$Response(params, context) {
    return getAircraftForIcaoCodes(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all aircraft matching icao.
   *
   * Returns all aircraft for the icao codes provided. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAircraftForIcaoCodes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftForIcaoCodes(params, context) {
    return this.getAircraftForIcaoCodes$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getAircraftBaseData()` */
  static {
    this.GetAircraftBaseDataPath = '/aircraft/base-data';
  }
  /**
   * Returns a list of AircraftBaseData.
   *
   * Returns a list of AircraftBaseData - i.e. the data that doesn't change over the life time of the aircraft. The only exception is the status (e.g. \"parked\") for which always the most current value is returned. Requires realm role "user" and aircraft masterdata client roles "am-user" and "access_aircraft_base_data". Doesn't authorize based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAircraftBaseData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftBaseData$Response(params, context) {
    return getAircraftBaseData(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns a list of AircraftBaseData.
   *
   * Returns a list of AircraftBaseData - i.e. the data that doesn't change over the life time of the aircraft. The only exception is the status (e.g. \"parked\") for which always the most current value is returned. Requires realm role "user" and aircraft masterdata client roles "am-user" and "access_aircraft_base_data". Doesn't authorize based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAircraftBaseData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftBaseData(params, context) {
    return this.getAircraftBaseData$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getAircraftHistory()` */
  static {
    this.GetAircraftHistoryPath = '/aircraft/history/{aircraftId}';
  }
  /**
   * Returns an array of aircraft history information.
   *
   * Returns a single aircraft history from external data source. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAircraftHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftHistory$Response(params, context) {
    return getAircraftHistory(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns an array of aircraft history information.
   *
   * Returns a single aircraft history from external data source. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAircraftHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftHistory(params, context) {
    return this.getAircraftHistory$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `setAircraftLabels()` */
  static {
    this.SetAircraftLabelsPath = '/aircraft/labels/{aircraftId}';
  }
  /**
   * saves labels for a given aircraft.
   *
   * Saves labels for a single aircraft. The labels are provided as string values in json formatted array within body. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setAircraftLabels()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAircraftLabels$Response(params, context) {
    return setAircraftLabels(this.http, this.rootUrl, params, context);
  }
  /**
   * saves labels for a given aircraft.
   *
   * Saves labels for a single aircraft. The labels are provided as string values in json formatted array within body. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setAircraftLabels$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAircraftLabels(params, context) {
    return this.setAircraftLabels$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getSamePhysicalAircraft()` */
  static {
    this.GetSamePhysicalAircraftPath = '/aircraft/same-physical/{aircraftId}';
  }
  /**
   * return an aircraft id's that represent the same physical aircraft.
   *
   * By passing an aircraft-id you will get a response of aircraft-id's that are the same physical aircraft within that partner-id. Such situation may occur for example on an aoc change of an aircraft where an new id is given. Requires realm role "user" and aircraft masterdata client role "am-user". Does not authorize based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSamePhysicalAircraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSamePhysicalAircraft$Response(params, context) {
    return getSamePhysicalAircraft(this.http, this.rootUrl, params, context);
  }
  /**
   * return an aircraft id's that represent the same physical aircraft.
   *
   * By passing an aircraft-id you will get a response of aircraft-id's that are the same physical aircraft within that partner-id. Such situation may occur for example on an aoc change of an aircraft where an new id is given. Requires realm role "user" and aircraft masterdata client role "am-user". Does not authorize based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSamePhysicalAircraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSamePhysicalAircraft(params, context) {
    return this.getSamePhysicalAircraft$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getAircraftById()` */
  static {
    this.GetAircraftByIdPath = '/aircraft/{aircraftId}';
  }
  /**
   * Returns a single aircraft.
   *
   * Returns a single aircraft. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAircraftById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftById$Response(params, context) {
    return getAircraftById(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns a single aircraft.
   *
   * Returns a single aircraft. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAircraftById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftById(params, context) {
    return this.getAircraftById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getAircraftIdByAcreg()` */
  static {
    this.GetAircraftIdByAcregPath = '/aircraftid-by-acreg';
  }
  /**
   * Returns a single aircraftId by acreg, date and partnerId. Even though partnerId is optional we strongly recommend providing it.
   *
   * Returns a single aircraftId by acreg and date. Both normal and unified acreg are accepted. Requires realm role "user" and aircraft masterdata client roles "am-user" and "resolve_acreg_to_acid". Doesn't authorize based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAircraftIdByAcreg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftIdByAcreg$Response(params, context) {
    return getAircraftIdByAcreg(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns a single aircraftId by acreg, date and partnerId. Even though partnerId is optional we strongly recommend providing it.
   *
   * Returns a single aircraftId by acreg and date. Both normal and unified acreg are accepted. Requires realm role "user" and aircraft masterdata client roles "am-user" and "resolve_acreg_to_acid". Doesn't authorize based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAircraftIdByAcreg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftIdByAcreg(params, context) {
    return this.getAircraftIdByAcreg$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getAircraftIdByAcregPart()` */
  static {
    this.GetAircraftIdByAcregPartPath = '/aircraftid-by-partial-acreg';
  }
  /**
   * Returns a list of AircraftRegistrationToAircraftIds where the partial acreg, date and partnerId match. Even though partnerId is optional we strongly recommend providing it.
   *
   * Returns a list of AircraftRegistrationToAircraftIds where the partial acreg and date match. Both normal and unified acreg are accepted.  Requires realm role "user" and aircraft masterdata client roles "am-user" and "resolve_acreg_to_acid". Doesn't authorize based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAircraftIdByAcregPart()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftIdByAcregPart$Response(params, context) {
    return getAircraftIdByAcregPart(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns a list of AircraftRegistrationToAircraftIds where the partial acreg, date and partnerId match. Even though partnerId is optional we strongly recommend providing it.
   *
   * Returns a list of AircraftRegistrationToAircraftIds where the partial acreg and date match. Both normal and unified acreg are accepted.  Requires realm role "user" and aircraft masterdata client roles "am-user" and "resolve_acreg_to_acid". Doesn't authorize based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAircraftIdByAcregPart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftIdByAcregPart(params, context) {
    return this.getAircraftIdByAcregPart$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getAllAircraftPartner()` */
  static {
    this.GetAllAircraftPartnerPath = '/partner/{partnerId}/aircraft/all';
  }
  /**
   * Returns all aircraft for one partner matching criteria.
   *
   * Returns all active and inactive aircraft visible for the user matching filter criteria. Requires realm role \"user\" and aircraft masterdata client roles "am-user" and "access_all_current_partner_aircraft". Doesn't authorize based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAircraftPartner()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAircraftPartner$Response(params, context) {
    return getAllAircraftPartner(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all aircraft for one partner matching criteria.
   *
   * Returns all active and inactive aircraft visible for the user matching filter criteria. Requires realm role \"user\" and aircraft masterdata client roles "am-user" and "access_all_current_partner_aircraft". Doesn't authorize based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAircraftPartner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAircraftPartner(params, context) {
    return this.getAllAircraftPartner$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getAircraftPartner()` */
  static {
    this.GetAircraftPartnerPath = '/partner/{partnerId}/aircraft/current';
  }
  /**
   * Returns all active aircraft for one partner matching criteria.
   *
   * Returns all active aircraft visible for the user matching filter criteria. Requires realm role \"user\" and aircraft masterdata client roles "am-user" and "access_all_current_partner_aircraft". Doesn't authorize based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAircraftPartner()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftPartner$Response(params, context) {
    return getAircraftPartner(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all active aircraft for one partner matching criteria.
   *
   * Returns all active aircraft visible for the user matching filter criteria. Requires realm role \"user\" and aircraft masterdata client roles "am-user" and "access_all_current_partner_aircraft". Doesn't authorize based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAircraftPartner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftPartner(params, context) {
    return this.getAircraftPartner$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getResolveAircraftIdOverTime()` */
  static {
    this.GetResolveAircraftIdOverTimePath = '/resolve-aircraftid-over-time';
  }
  /**
   * Returns an array of aircraft with aircraftId by (partial) registration, date range and partnerId. By default contains logic is used to resolve the registration so you have to validate the requestedRegistration against the returned fullRegistration.
   *
   * Returns a single aircraftId by registration and date. Both normal and unified registrations are accepted. Requires realm role "user" and aircraft master data client roles "am-user" and "resolve_acreg_to_acid". Doesn't authorize based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResolveAircraftIdOverTime()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResolveAircraftIdOverTime$Response(params, context) {
    return getResolveAircraftIdOverTime(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns an array of aircraft with aircraftId by (partial) registration, date range and partnerId. By default contains logic is used to resolve the registration so you have to validate the requestedRegistration against the returned fullRegistration.
   *
   * Returns a single aircraftId by registration and date. Both normal and unified registrations are accepted. Requires realm role "user" and aircraft master data client roles "am-user" and "resolve_acreg_to_acid". Doesn't authorize based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResolveAircraftIdOverTime$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResolveAircraftIdOverTime(params, context) {
    return this.getResolveAircraftIdOverTime$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function AircraftService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AircraftService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AircraftService,
      factory: AircraftService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AircraftService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAcDetailProvider(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAcDetailProvider.PATH, 'get');
  if (params) {
    rb.path('detailName', params.detailName, {});
    rb.header('app_id', params.app_id, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAcDetailProvider.PATH = '/aircraft-detail-providers/{detailName}';
class AircraftDetailProviderService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getAcDetailProvider()` */
  static {
    this.GetAcDetailProviderPath = '/aircraft-detail-providers/{detailName}';
  }
  /**
   * Retrieve one aircraft detail provider.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAcDetailProvider()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAcDetailProvider$Response(params, context) {
    return getAcDetailProvider(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve one aircraft detail provider.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAcDetailProvider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAcDetailProvider(params, context) {
    return this.getAcDetailProvider$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function AircraftDetailProviderService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AircraftDetailProviderService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AircraftDetailProviderService,
      factory: AircraftDetailProviderService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AircraftDetailProviderService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function deleteSingleAircraftLock(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, deleteSingleAircraftLock.PATH, 'delete');
  if (params) {
    rb.path('aircraftId', params.aircraftId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
deleteSingleAircraftLock.PATH = '/aircraft/{aircraftId}/lock';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getLockableAircraft(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getLockableAircraft.PATH, 'get');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getLockableAircraft.PATH = '/aircraft-lockable';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getSingleAircraftLock(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getSingleAircraftLock.PATH, 'get');
  if (params) {
    rb.path('aircraftId', params.aircraftId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getSingleAircraftLock.PATH = '/aircraft/{aircraftId}/lock';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function lockSingleAircraft(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, lockSingleAircraft.PATH, 'post');
  if (params) {
    rb.path('aircraftId', params.aircraftId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
lockSingleAircraft.PATH = '/aircraft/{aircraftId}/lock';

/**
 * Hide aircraft from users without special privileges
 */
class AircraftLockingService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getLockableAircraft()` */
  static {
    this.GetLockableAircraftPath = '/aircraft-lockable';
  }
  /**
   * Gets the lock status for all permitted user aircraft.
   *
   * Gets the lock status for all permitted user aircraft. Requires realm role "user" and aircraft masterdata client roles "am-user" and "app_aircraft_access_manager". Authorizes based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLockableAircraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLockableAircraft$Response(params, context) {
    return getLockableAircraft(this.http, this.rootUrl, params, context);
  }
  /**
   * Gets the lock status for all permitted user aircraft.
   *
   * Gets the lock status for all permitted user aircraft. Requires realm role "user" and aircraft masterdata client roles "am-user" and "app_aircraft_access_manager". Authorizes based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLockableAircraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLockableAircraft(params, context) {
    return this.getLockableAircraft$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getSingleAircraftLock()` */
  static {
    this.GetSingleAircraftLockPath = '/aircraft/{aircraftId}/lock';
  }
  /**
   * Gets the lock status for the specified aircraft.
   *
   * Gets the lock status for the specified aircraft.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleAircraftLock()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleAircraftLock$Response(params, context) {
    return getSingleAircraftLock(this.http, this.rootUrl, params, context);
  }
  /**
   * Gets the lock status for the specified aircraft.
   *
   * Gets the lock status for the specified aircraft.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleAircraftLock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleAircraftLock(params, context) {
    return this.getSingleAircraftLock$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `lockSingleAircraft()` */
  static {
    this.LockSingleAircraftPath = '/aircraft/{aircraftId}/lock';
  }
  /**
   * Locks the specified aircraft.
   *
   * Sets a lock on the specified aircraft, which makes it unavailable throughout the platform. No application will be able to see data for the specified aircraft. Requires realm role "user" and aircraft masterdata client roles "am-user" and "app_aircraft_access_manager". Authorizes based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lockSingleAircraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockSingleAircraft$Response(params, context) {
    return lockSingleAircraft(this.http, this.rootUrl, params, context);
  }
  /**
   * Locks the specified aircraft.
   *
   * Sets a lock on the specified aircraft, which makes it unavailable throughout the platform. No application will be able to see data for the specified aircraft. Requires realm role "user" and aircraft masterdata client roles "am-user" and "app_aircraft_access_manager". Authorizes based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lockSingleAircraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockSingleAircraft(params, context) {
    return this.lockSingleAircraft$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `deleteSingleAircraftLock()` */
  static {
    this.DeleteSingleAircraftLockPath = '/aircraft/{aircraftId}/lock';
  }
  /**
   * Removes lock on the specified aircraft.
   *
   * Removes lock on the specified aircraft.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSingleAircraftLock()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSingleAircraftLock$Response(params, context) {
    return deleteSingleAircraftLock(this.http, this.rootUrl, params, context);
  }
  /**
   * Removes lock on the specified aircraft.
   *
   * Removes lock on the specified aircraft.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSingleAircraftLock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSingleAircraftLock(params, context) {
    return this.deleteSingleAircraftLock$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function AircraftLockingService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AircraftLockingService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AircraftLockingService,
      factory: AircraftLockingService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AircraftLockingService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAircraftOperator(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAircraftOperator.PATH, 'get');
  if (params) {
    rb.query('id', params.id, {});
    rb.query('codeIcao', params.codeIcao, {});
    rb.query('codeIata', params.codeIata, {});
    rb.query('partnerId', params.partnerId, {});
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAircraftOperator.PATH = '/aircraft-operator';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAircraftOperatorByIcaoCode(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAircraftOperatorByIcaoCode.PATH, 'get');
  if (params) {
    rb.path('icaoCode', params.icaoCode, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAircraftOperatorByIcaoCode.PATH = '/aircraft-operator/icao/{icaoCode}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAircraftOperatorById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAircraftOperatorById.PATH, 'get');
  if (params) {
    rb.path('operatorId', params.operatorId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAircraftOperatorById.PATH = '/aircraft-operator/{operatorId}';
class AircraftOperatorService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getAircraftOperator()` */
  static {
    this.GetAircraftOperatorPath = '/aircraft-operator';
  }
  /**
   * Returns all aircraft operators matching criteria.
   *
   * Returns all aircraft operators matching filter criteria. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAircraftOperator()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftOperator$Response(params, context) {
    return getAircraftOperator(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all aircraft operators matching criteria.
   *
   * Returns all aircraft operators matching filter criteria. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAircraftOperator$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftOperator(params, context) {
    return this.getAircraftOperator$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getAircraftOperatorByIcaoCode()` */
  static {
    this.GetAircraftOperatorByIcaoCodePath = '/aircraft-operator/icao/{icaoCode}';
  }
  /**
   * Returns a single aircraft operator.
   *
   * Returns a single aircraft operator by ICAO code. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAircraftOperatorByIcaoCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftOperatorByIcaoCode$Response(params, context) {
    return getAircraftOperatorByIcaoCode(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns a single aircraft operator.
   *
   * Returns a single aircraft operator by ICAO code. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAircraftOperatorByIcaoCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftOperatorByIcaoCode(params, context) {
    return this.getAircraftOperatorByIcaoCode$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getAircraftOperatorById()` */
  static {
    this.GetAircraftOperatorByIdPath = '/aircraft-operator/{operatorId}';
  }
  /**
   * Returns a single aircraft operator.
   *
   * Returns a single aircraft operator by id. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAircraftOperatorById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftOperatorById$Response(params, context) {
    return getAircraftOperatorById(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns a single aircraft operator.
   *
   * Returns a single aircraft operator by id. Requires realm role "user" and aircraft masterdata client role "am-user". Authorizes based on aircraftId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAircraftOperatorById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftOperatorById(params, context) {
    return this.getAircraftOperatorById$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function AircraftOperatorService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AircraftOperatorService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AircraftOperatorService,
      factory: AircraftOperatorService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AircraftOperatorService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getLivePositions(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getLivePositions.PATH, 'get');
  if (params) {
    rb.query('id', params.id, {
      "style": "form",
      "explode": false
    });
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getLivePositions.PATH = '/aircraft-positions';

/**
 * The latest position in the flown route
 */
class LivePositionService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getLivePositions()` */
  static {
    this.GetLivePositionsPath = '/aircraft-positions';
  }
  /**
   * The latest position of the aircraft with this registration.
   *
   * The first element of the route position list is the latest position
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLivePositions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLivePositions$Response(params, context) {
    return getLivePositions(this.http, this.rootUrl, params, context);
  }
  /**
   * The latest position of the aircraft with this registration.
   *
   * The first element of the route position list is the latest position
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLivePositions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLivePositions(params, context) {
    return this.getLivePositions$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function LivePositionService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LivePositionService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LivePositionService,
      factory: LivePositionService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LivePositionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getFlightInformationForAircraft(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getFlightInformationForAircraft.PATH, 'get');
  if (params) {
    rb.query('aircraft-id', params['aircraft-id'], {
      "style": "form",
      "explode": true
    });
    rb.query('from', params.from, {});
    rb.query('to', params.to, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getFlightInformationForAircraft.PATH = '/flight-information';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getLatestAircraftOperationInformation(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getLatestAircraftOperationInformation.PATH, 'get');
  if (params) {
    rb.query('aircraft-id', params['aircraft-id'], {
      "style": "form",
      "explode": true
    });
    rb.query('previous-aircraft-operations-limit', params['previous-aircraft-operations-limit'], {});
    rb.query('from', params.from, {});
    rb.query('to', params.to, {});
    rb.query('exclude-legs-without-oooi', params['exclude-legs-without-oooi'], {});
    rb.query('include-fuel-history', params['include-fuel-history'], {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getLatestAircraftOperationInformation.PATH = '/latest-aircraft-operation-information';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getOperatedLegById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getOperatedLegById.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getOperatedLegById.PATH = '/operated-legs/{id}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getOperatedLegsWithinTimeFrame(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getOperatedLegsWithinTimeFrame.PATH, 'get');
  if (params) {
    rb.query('aircraft-id', params['aircraft-id'], {
      "style": "form",
      "explode": true
    });
    rb.query('from', params.from, {});
    rb.query('to', params.to, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getOperatedLegsWithinTimeFrame.PATH = '/operated-legs';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getScheduledFlightById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getScheduledFlightById.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getScheduledFlightById.PATH = '/scheduled-flights/{id}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getScheduledLegById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getScheduledLegById.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getScheduledLegById.PATH = '/scheduled-legs/{id}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getScheduledLegs(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getScheduledLegs.PATH, 'get');
  if (params) {
    rb.query('from', params.from, {});
    rb.query('to', params.to, {});
    rb.query('flight-designator', params['flight-designator'], {
      "style": "form",
      "explode": false
    });
    rb.query('flight-date', params['flight-date'], {
      "style": "form",
      "explode": false
    });
    rb.query('arrival-airport-iata-code', params['arrival-airport-iata-code'], {
      "style": "form",
      "explode": false
    });
    rb.query('departure-airport-iata-code', params['departure-airport-iata-code'], {
      "style": "form",
      "explode": false
    });
    rb.query('flight-reason', params['flight-reason'], {
      "style": "form",
      "explode": false
    });
    rb.query('partnerId', params.partnerId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getScheduledLegs.PATH = '/scheduled-legs';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getScheduledLegsForAircraft(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getScheduledLegsForAircraft.PATH, 'get');
  if (params) {
    rb.query('aircraft-id', params['aircraft-id'], {
      "style": "form",
      "explode": true
    });
    rb.query('from', params.from, {});
    rb.query('onward-scheduled-legs-limit', params['onward-scheduled-legs-limit'], {});
    rb.query('scheduled-legs-per-aircraft-limit', params['scheduled-legs-per-aircraft-limit'], {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getScheduledLegsForAircraft.PATH = '/aircraft-rotation-plan';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getStatistics(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getStatistics.PATH, 'get');
  if (params) {
    rb.query('aircraft-id', params['aircraft-id'], {
      "style": "form",
      "explode": true
    });
    rb.query('from', params.from, {});
    rb.query('to', params.to, {});
    rb.query('statistics', params.statistics, {
      "style": "form",
      "explode": false
    });
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getStatistics.PATH = '/statistics';

/**
 * Information about operated and scheduled flights and legs
 */
class FlightsService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getScheduledLegsForAircraft()` */
  static {
    this.GetScheduledLegsForAircraftPath = '/aircraft-rotation-plan';
  }
  /**
   * Returns scheduled legs that are known to be executed by a specific aircraft.
   *
   * Returns scheduled legs that are known to be executed by a specific aircraft
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScheduledLegsForAircraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScheduledLegsForAircraft$Response(params, context) {
    return getScheduledLegsForAircraft(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns scheduled legs that are known to be executed by a specific aircraft.
   *
   * Returns scheduled legs that are known to be executed by a specific aircraft
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScheduledLegsForAircraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScheduledLegsForAircraft(params, context) {
    return this.getScheduledLegsForAircraft$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getFlightInformationForAircraft()` */
  static {
    this.GetFlightInformationForAircraftPath = '/flight-information';
  }
  /**
   * Returns flight-information for a set of aircraft-ids.
   *
   * Provides all scheduled information (future, current and past) for a set of aircraft-ids. Those information are enriched with information from the correlating operated legs if available.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFlightInformationForAircraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlightInformationForAircraft$Response(params, context) {
    return getFlightInformationForAircraft(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns flight-information for a set of aircraft-ids.
   *
   * Provides all scheduled information (future, current and past) for a set of aircraft-ids. Those information are enriched with information from the correlating operated legs if available.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFlightInformationForAircraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlightInformationForAircraft(params, context) {
    return this.getFlightInformationForAircraft$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getLatestAircraftOperationInformation()` */
  static {
    this.GetLatestAircraftOperationInformationPath = '/latest-aircraft-operation-information';
  }
  /**
   * Returns the latest operation information for a set of aircraft ids.
   *
   * The best guess of the current state of the aircraft considering all data sources. Returns the latest operated leg information correlated with scheduled information if available including diversions etc. Instead of querying the latest N aircraft operation information you can also query the latest aircraft operation information in a specified time frame. In that case all legs with the technical begin time within the time frame (between from and to) are returned. The time frame has to be in UTC.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestAircraftOperationInformation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestAircraftOperationInformation$Response(params, context) {
    return getLatestAircraftOperationInformation(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns the latest operation information for a set of aircraft ids.
   *
   * The best guess of the current state of the aircraft considering all data sources. Returns the latest operated leg information correlated with scheduled information if available including diversions etc. Instead of querying the latest N aircraft operation information you can also query the latest aircraft operation information in a specified time frame. In that case all legs with the technical begin time within the time frame (between from and to) are returned. The time frame has to be in UTC.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestAircraftOperationInformation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestAircraftOperationInformation(params, context) {
    return this.getLatestAircraftOperationInformation$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getOperatedLegsWithinTimeFrame()` */
  static {
    this.GetOperatedLegsWithinTimeFramePath = '/operated-legs';
  }
  /**
   * Returns operated legs within a timeframe.
   *
   * Returns operated legs for an optional given time frame specified as 'from' and 'to' for a set of aircraft ids. The operated leg is included in the result if the technical begin time is within the time frame. The time frame has to be in UTC.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOperatedLegsWithinTimeFrame()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOperatedLegsWithinTimeFrame$Response(params, context) {
    return getOperatedLegsWithinTimeFrame(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns operated legs within a timeframe.
   *
   * Returns operated legs for an optional given time frame specified as 'from' and 'to' for a set of aircraft ids. The operated leg is included in the result if the technical begin time is within the time frame. The time frame has to be in UTC.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOperatedLegsWithinTimeFrame$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOperatedLegsWithinTimeFrame(params, context) {
    return this.getOperatedLegsWithinTimeFrame$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getOperatedLegById()` */
  static {
    this.GetOperatedLegByIdPath = '/operated-legs/{id}';
  }
  /**
   * Returns operated leg with a specific id.
   *
   * Returns operated leg with a specific id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOperatedLegById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOperatedLegById$Response(params, context) {
    return getOperatedLegById(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns operated leg with a specific id.
   *
   * Returns operated leg with a specific id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOperatedLegById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOperatedLegById(params, context) {
    return this.getOperatedLegById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getScheduledFlightById()` */
  static {
    this.GetScheduledFlightByIdPath = '/scheduled-flights/{id}';
  }
  /**
   * Returns scheduled flight with a specific id.
   *
   * Returns scheduled flight with a specific id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScheduledFlightById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScheduledFlightById$Response(params, context) {
    return getScheduledFlightById(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns scheduled flight with a specific id.
   *
   * Returns scheduled flight with a specific id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScheduledFlightById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScheduledFlightById(params, context) {
    return this.getScheduledFlightById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getScheduledLegs()` */
  static {
    this.GetScheduledLegsPath = '/scheduled-legs';
  }
  /**
   * Returns scheduled legs.
   *
   * Returns scheduled legs based on the specified search criteria. At least one criterion is required.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScheduledLegs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScheduledLegs$Response(params, context) {
    return getScheduledLegs(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns scheduled legs.
   *
   * Returns scheduled legs based on the specified search criteria. At least one criterion is required.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScheduledLegs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScheduledLegs(params, context) {
    return this.getScheduledLegs$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getScheduledLegById()` */
  static {
    this.GetScheduledLegByIdPath = '/scheduled-legs/{id}';
  }
  /**
   * Returns scheduled leg with a specific id.
   *
   * Returns scheduled leg with a specific id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScheduledLegById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScheduledLegById$Response(params, context) {
    return getScheduledLegById(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns scheduled leg with a specific id.
   *
   * Returns scheduled leg with a specific id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScheduledLegById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScheduledLegById(params, context) {
    return this.getScheduledLegById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getStatistics()` */
  static {
    this.GetStatisticsPath = '/statistics';
  }
  /**
   * Returns statistics about operated legs in a given time frame.
   *
   * Returns statistics about operated legs per aircraft for a given time frame in UTC (e.g. operated legs per aircraft within time frame).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatistics$Response(params, context) {
    return getStatistics(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns statistics about operated legs in a given time frame.
   *
   * Returns statistics about operated legs per aircraft for a given time frame in UTC (e.g. operated legs per aircraft within time frame).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatistics(params, context) {
    return this.getStatistics$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function FlightsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlightsService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FlightsService,
      factory: FlightsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlightsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAircraftDetailCurrentStatus(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAircraftDetailCurrentStatus.PATH, 'get');
  if (params) {
    rb.query('id', params.id, {
      "explode": true
    });
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAircraftDetailCurrentStatus.PATH = '/aircraft-statuses/current';

/**
 * Current status of an aircraft.
 */
class CurrentStatusService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getAircraftDetailCurrentStatus()` */
  static {
    this.GetAircraftDetailCurrentStatusPath = '/aircraft-statuses/current';
  }
  /**
   * Returns the current status of a list of aircraft.
   *
   * Returns the current status of a list of aircraft. This is the current operation status of all the aircraft requested.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAircraftDetailCurrentStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftDetailCurrentStatus$Response(params, context) {
    return getAircraftDetailCurrentStatus(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns the current status of a list of aircraft.
   *
   * Returns the current status of a list of aircraft. This is the current operation status of all the aircraft requested.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAircraftDetailCurrentStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAircraftDetailCurrentStatus(params, context) {
    return this.getAircraftDetailCurrentStatus$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function CurrentStatusService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CurrentStatusService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CurrentStatusService,
      factory: CurrentStatusService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CurrentStatusService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAirportByCode(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAirportByCode.PATH, 'get');
  if (params) {
    rb.path('airportCode', params.airportCode, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAirportByCode.PATH = '/airports/{airportCode}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAirports(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAirports.PATH, 'get');
  if (params) {
    rb.query('iataCode', params.iataCode, {
      "style": "form",
      "explode": false
    });
    rb.query('icaoCode', params.icaoCode, {
      "style": "form",
      "explode": false
    });
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAirports.PATH = '/airports';

/**
 * Provides information on airports.
 */
class AirportsService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getAirports()` */
  static {
    this.GetAirportsPath = '/airports';
  }
  /**
   * Returns all airports.
   *
   * Returns all airports.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAirports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAirports$Response(params, context) {
    return getAirports(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all airports.
   *
   * Returns all airports.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAirports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAirports(params, context) {
    return this.getAirports$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getAirportByCode()` */
  static {
    this.GetAirportByCodePath = '/airports/{airportCode}';
  }
  /**
   * Find airport by airport code.
   *
   * Returns a single airport.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAirportByCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAirportByCode$Response(params, context) {
    return getAirportByCode(this.http, this.rootUrl, params, context);
  }
  /**
   * Find airport by airport code.
   *
   * Returns a single airport.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAirportByCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAirportByCode(params, context) {
    return this.getAirportByCode$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function AirportsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AirportsService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AirportsService,
      factory: AirportsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AirportsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getInfo(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getInfo.PATH, 'get');
  if (params) {
    rb.path('woNumber', params.woNumber, {});
    rb.path('aircraftId', params.aircraftId, {});
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: 'text/html',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getInfo.PATH = '/amoscentral/info/workorder/{woNumber}/aircraft/{aircraftId}';
class AmosCentralRequestInfoServiceService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getInfo()` */
  static {
    this.GetInfoPath = '/amoscentral/info/workorder/{woNumber}/aircraft/{aircraftId}';
  }
  /**
   * Get workorder instant information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInfo$Response(params, context) {
    return getInfo(this.http, this.rootUrl, params, context);
  }
  /**
   * Get workorder instant information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInfo(params, context) {
    return this.getInfo$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function AmosCentralRequestInfoServiceService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AmosCentralRequestInfoServiceService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AmosCentralRequestInfoServiceService,
      factory: AmosCentralRequestInfoServiceService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AmosCentralRequestInfoServiceService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getPdf(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getPdf.PATH, 'get');
  if (params) {
    rb.path('woNumber', params.woNumber, {});
    rb.path('aircraftId', params.aircraftId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'application/octet-stream',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getPdf.PATH = '/amoscentral/pdf/workorder/{woNumber}/aircraft/{aircraftId}';
class AmosCentralRequestPdfServiceService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getPdf()` */
  static {
    this.GetPdfPath = '/amoscentral/pdf/workorder/{woNumber}/aircraft/{aircraftId}';
  }
  /**
   * Get workorder pdf .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPdf$Response(params, context) {
    return getPdf(this.http, this.rootUrl, params, context);
  }
  /**
   * Get workorder pdf .
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPdf(params, context) {
    return this.getPdf$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function AmosCentralRequestPdfServiceService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AmosCentralRequestPdfServiceService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AmosCentralRequestPdfServiceService,
      factory: AmosCentralRequestPdfServiceService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AmosCentralRequestPdfServiceService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getApplications(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getApplications.PATH, 'get');
  if (params) {
    rb.query('id', params.id, {
      "style": "form",
      "explode": true
    });
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
    rb.header('app_id', params.app_id, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getApplications.PATH = '/applications';
class ApplicationService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getApplications()` */
  static {
    this.GetApplicationsPath = '/applications';
  }
  /**
   * Returns all or filtered applications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplications$Response(params, context) {
    return getApplications(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all or filtered applications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplications(params, context) {
    return this.getApplications$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function ApplicationService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ApplicationService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ApplicationService,
      factory: ApplicationService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApplicationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function componentsGet(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, componentsGet.PATH, 'get');
  if (params) {
    rb.query('partnerId', params.partnerId, {});
    rb.query('partNumber', params.partNumber, {});
    rb.query('serialNumber', params.serialNumber, {});
    rb.query('includeHistory', params.includeHistory, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('include', params.include, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
componentsGet.PATH = '/components-v2';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function componentsGetForAircraft(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, componentsGetForAircraft.PATH, 'get');
  if (params) {
    rb.path('aircraftId', params.aircraftId, {});
    rb.query('partnerId', params.partnerId, {});
    rb.query('partNumber', params.partNumber, {});
    rb.query('serialNumber', params.serialNumber, {});
    rb.query('materialType', params.materialType, {
      "explode": true
    });
    rb.query('timestamp', params.timestamp, {});
    rb.query('timeFrom', params.timeFrom, {});
    rb.query('timeTo', params.timeTo, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('include', params.include, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
componentsGetForAircraft.PATH = '/components-v2/{aircraftId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function componentsSingleGet(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, componentsSingleGet.PATH, 'get');
  if (params) {
    rb.path('partnerId', params.partnerId, {});
    rb.path('componentId', params.componentId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
componentsSingleGet.PATH = '/components-v2/partner/{partnerId}/component/{componentId}';

/**
 * Components Service V2
 */
class ComponentsServiceV2Service extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `componentsGet()` */
  static {
    this.ComponentsGetPath = '/components-v2';
  }
  /**
   * Get response with component list. This will give you the current state or complete history of one specific part.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `componentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  componentsGet$Response(params, context) {
    return componentsGet(this.http, this.rootUrl, params, context);
  }
  /**
   * Get response with component list. This will give you the current state or complete history of one specific part.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `componentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  componentsGet(params, context) {
    return this.componentsGet$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `componentsSingleGet()` */
  static {
    this.ComponentsSingleGetPath = '/components-v2/partner/{partnerId}/component/{componentId}';
  }
  /**
   * Get single component. Basically for frontend requests. No special role required, just ar_ROLE to access aircraft.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `componentsSingleGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  componentsSingleGet$Response(params, context) {
    return componentsSingleGet(this.http, this.rootUrl, params, context);
  }
  /**
   * Get single component. Basically for frontend requests. No special role required, just ar_ROLE to access aircraft.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `componentsSingleGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  componentsSingleGet(params, context) {
    return this.componentsSingleGet$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `componentsGetForAircraft()` */
  static {
    this.ComponentsGetForAircraftPath = '/components-v2/{aircraftId}';
  }
  /**
   * Get response with component list for an aircraft. timeframe search and timestamp search cant be mixed. timeframe is limited to one component and will give you all components that have been installed on a specific aircraft in the given timeframe no matter where the component is at the moment. Timestamp search will give you all installed components for that point in time is not limited to one part number. If you leave both empty the search defaults to current timestamp.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `componentsGetForAircraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  componentsGetForAircraft$Response(params, context) {
    return componentsGetForAircraft(this.http, this.rootUrl, params, context);
  }
  /**
   * Get response with component list for an aircraft. timeframe search and timestamp search cant be mixed. timeframe is limited to one component and will give you all components that have been installed on a specific aircraft in the given timeframe no matter where the component is at the moment. Timestamp search will give you all installed components for that point in time is not limited to one part number. If you leave both empty the search defaults to current timestamp.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `componentsGetForAircraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  componentsGetForAircraft(params, context) {
    return this.componentsGetForAircraft$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function ComponentsServiceV2Service_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ComponentsServiceV2Service)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ComponentsServiceV2Service,
      factory: ComponentsServiceV2Service.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ComponentsServiceV2Service, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getTagMapping(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getTagMapping.PATH, 'get');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getTagMapping.PATH = '/control-center/tag-mapping.json';

/**
 * Tags
 */
class TagsService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getTagMapping()` */
  static {
    this.GetTagMappingPath = '/control-center/tag-mapping.json';
  }
  /**
   * Color and icon mapping for Tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTagMapping()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTagMapping$Response(params, context) {
    return getTagMapping(this.http, this.rootUrl, params, context);
  }
  /**
   * Color and icon mapping for Tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTagMapping$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTagMapping(params, context) {
    return this.getTagMapping$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function TagsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TagsService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TagsService,
      factory: TagsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TagsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getCurrentAlerts(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getCurrentAlerts.PATH, 'get');
  if (params) {
    rb.query('aircraftId', params.aircraftId, {
      "explode": true
    });
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getCurrentAlerts.PATH = '/current-alerts';

/**
 * Current alerts of an aircraft.
 */
class CurrentAlertsService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getCurrentAlerts()` */
  static {
    this.GetCurrentAlertsPath = '/current-alerts';
  }
  /**
   * Returns all current alerts of aircraft one or multiple matching criteria.
   *
   * Returns all current alerts matching filter criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentAlerts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentAlerts$Response(params, context) {
    return getCurrentAlerts(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all current alerts of aircraft one or multiple matching criteria.
   *
   * Returns all current alerts matching filter criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentAlerts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentAlerts(params, context) {
    return this.getCurrentAlerts$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function CurrentAlertsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CurrentAlertsService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CurrentAlertsService,
      factory: CurrentAlertsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CurrentAlertsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getExtensionComponents(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getExtensionComponents.PATH, 'get');
  if (params) {
    rb.path('context', params.context, {});
    rb.path('type', params.type, {});
    rb.header('app_id', params.app_id, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getExtensionComponents.PATH = '/extension-components/{context}/{type}';
class ExtensionComponentService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getExtensionComponents()` */
  static {
    this.GetExtensionComponentsPath = '/extension-components/{context}/{type}';
  }
  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExtensionComponents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtensionComponents$Response(params, context) {
    return getExtensionComponents(this.http, this.rootUrl, params, context);
  }
  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExtensionComponents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtensionComponents(params, context) {
    return this.getExtensionComponents$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function ExtensionComponentService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ExtensionComponentService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ExtensionComponentService,
      factory: ExtensionComponentService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExtensionComponentService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function createMasterDataV2(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, createMasterDataV2.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
createMasterDataV2.PATH = '/fault-master-data-api/v2/create';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getFlightPhases(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getFlightPhases.PATH, 'get');
  if (params) {
    rb.path('partnerId', params.partnerId, {});
    rb.path('aircraftSeparator', params.aircraftSeparator, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getFlightPhases.PATH = '/fault-master-data-api/v2/partner/{partnerId}/aircraft-separator/{aircraftSeparator}/flight-phases';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getMasterDataFiltered(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getMasterDataFiltered.PATH, 'get');
  if (params) {
    rb.path('partnerId', params.partnerId, {});
    rb.path('aircraftSeparator', params.aircraftSeparator, {});
    rb.query('messageCode', params.messageCode, {});
    rb.query('messageText', params.messageText, {});
    rb.query('source', params.source, {});
    rb.query('ata', params.ata, {});
    rb.query('masterDataType', params.masterDataType, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getMasterDataFiltered.PATH = '/fault-master-data-api/v3/partner/{partnerId}/aircraft-separator/{aircraftSeparator}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getMasterDataV2All(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getMasterDataV2All.PATH, 'get');
  if (params) {
    rb.path('partnerId', params.partnerId, {});
    rb.path('aircraftSeparator', params.aircraftSeparator, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getMasterDataV2All.PATH = '/fault-master-data-api/v2/partner/{partnerId}/aircraft-separator/{aircraftSeparator}/all';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getSourceAta2FallbackMapping(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getSourceAta2FallbackMapping.PATH, 'get');
  if (params) {
    rb.path('aircraftSeparator', params.aircraftSeparator, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getSourceAta2FallbackMapping.PATH = '/fault-master-data-api/v2/aircraft-separator/{aircraftSeparator}/source-ata2-fallback-mapping';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getSourceAta4Mapping(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getSourceAta4Mapping.PATH, 'get');
  if (params) {
    rb.path('partnerId', params.partnerId, {});
    rb.path('aircraftSeparator', params.aircraftSeparator, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getSourceAta4Mapping.PATH = '/fault-master-data-api/v2/partner/{partnerId}/aircraft-separator/{aircraftSeparator}/source-ata4-mapping';
class FaultMasterDataApiV2MasterDataService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getSourceAta2FallbackMapping()` */
  static {
    this.GetSourceAta2FallbackMappingPath = '/fault-master-data-api/v2/aircraft-separator/{aircraftSeparator}/source-ata2-fallback-mapping';
  }
  /**
   * Get the complete source to ata 2 fallback mapping for an aircraft separator.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSourceAta2FallbackMapping()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSourceAta2FallbackMapping$Response(params, context) {
    return getSourceAta2FallbackMapping(this.http, this.rootUrl, params, context);
  }
  /**
   * Get the complete source to ata 2 fallback mapping for an aircraft separator.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSourceAta2FallbackMapping$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSourceAta2FallbackMapping(params, context) {
    return this.getSourceAta2FallbackMapping$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `createMasterDataV2()` */
  static {
    this.CreateMasterDataV2Path = '/fault-master-data-api/v2/create';
  }
  /**
   * Exclusive usage for Fault Parsing. Creates the fault master data entry by message code and returns the new entry. \ If there already exists a fault master data entry for the provided message code the existing one will not be overwritten and the existing entry will be returned.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMasterDataV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMasterDataV2$Response(params, context) {
    return createMasterDataV2(this.http, this.rootUrl, params, context);
  }
  /**
   * Exclusive usage for Fault Parsing. Creates the fault master data entry by message code and returns the new entry. \ If there already exists a fault master data entry for the provided message code the existing one will not be overwritten and the existing entry will be returned.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMasterDataV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMasterDataV2(params, context) {
    return this.createMasterDataV2$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getMasterDataV2All()` */
  static {
    this.GetMasterDataV2AllPath = '/fault-master-data-api/v2/partner/{partnerId}/aircraft-separator/{aircraftSeparator}/all';
  }
  /**
   * Get the complete fault master data for an aircraft separator of a partner. Access restricted to Event Based Action.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMasterDataV2All()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterDataV2All$Response(params, context) {
    return getMasterDataV2All(this.http, this.rootUrl, params, context);
  }
  /**
   * Get the complete fault master data for an aircraft separator of a partner. Access restricted to Event Based Action.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMasterDataV2All$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterDataV2All(params, context) {
    return this.getMasterDataV2All$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getFlightPhases()` */
  static {
    this.GetFlightPhasesPath = '/fault-master-data-api/v2/partner/{partnerId}/aircraft-separator/{aircraftSeparator}/flight-phases';
  }
  /**
   * Get the flight phases for a certain partner and aircraft separator.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFlightPhases()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlightPhases$Response(params, context) {
    return getFlightPhases(this.http, this.rootUrl, params, context);
  }
  /**
   * Get the flight phases for a certain partner and aircraft separator.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFlightPhases$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlightPhases(params, context) {
    return this.getFlightPhases$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getSourceAta4Mapping()` */
  static {
    this.GetSourceAta4MappingPath = '/fault-master-data-api/v2/partner/{partnerId}/aircraft-separator/{aircraftSeparator}/source-ata4-mapping';
  }
  /**
   * Get the complete source to ata 4 mapping for a partner's aircraft separator.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSourceAta4Mapping()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSourceAta4Mapping$Response(params, context) {
    return getSourceAta4Mapping(this.http, this.rootUrl, params, context);
  }
  /**
   * Get the complete source to ata 4 mapping for a partner's aircraft separator.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSourceAta4Mapping$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSourceAta4Mapping(params, context) {
    return this.getSourceAta4Mapping$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getMasterDataFiltered()` */
  static {
    this.GetMasterDataFilteredPath = '/fault-master-data-api/v3/partner/{partnerId}/aircraft-separator/{aircraftSeparator}';
  }
  /**
   * Get the filtered master data for partner and aircraft separator with a MasterDataType. At least one filter of messageCode or messageText must be set otherwise HTTP 409 will be returned.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMasterDataFiltered()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterDataFiltered$Response(params, context) {
    return getMasterDataFiltered(this.http, this.rootUrl, params, context);
  }
  /**
   * Get the filtered master data for partner and aircraft separator with a MasterDataType. At least one filter of messageCode or messageText must be set otherwise HTTP 409 will be returned.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMasterDataFiltered$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterDataFiltered(params, context) {
    return this.getMasterDataFiltered$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function FaultMasterDataApiV2MasterDataService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FaultMasterDataApiV2MasterDataService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FaultMasterDataApiV2MasterDataService,
      factory: FaultMasterDataApiV2MasterDataService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FaultMasterDataApiV2MasterDataService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getPrioritiesV2ByMessageMasterDataId(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getPrioritiesV2ByMessageMasterDataId.PATH, 'get');
  if (params) {
    rb.path('partnerId', params.partnerId, {});
    rb.path('aircraftSeparator', params.aircraftSeparator, {});
    rb.path('messageMasterDataId', params.messageMasterDataId, {});
    rb.query('flightPhase', params.flightPhase, {});
    rb.query('validAt', params.validAt, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getPrioritiesV2ByMessageMasterDataId.PATH = '/fault-master-data-api/v2/partner/{partnerId}/aircraft-separator/{aircraftSeparator}/message-master-data-id/{messageMasterDataId}/priorities';
class FaultMasterDataApiV2PrioritiesService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getPrioritiesV2ByMessageMasterDataId()` */
  static {
    this.GetPrioritiesV2ByMessageMasterDataIdPath = '/fault-master-data-api/v2/partner/{partnerId}/aircraft-separator/{aircraftSeparator}/message-master-data-id/{messageMasterDataId}/priorities';
  }
  /**
   * Get the priorities for flight phase by message master data id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrioritiesV2ByMessageMasterDataId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrioritiesV2ByMessageMasterDataId$Response(params, context) {
    return getPrioritiesV2ByMessageMasterDataId(this.http, this.rootUrl, params, context);
  }
  /**
   * Get the priorities for flight phase by message master data id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPrioritiesV2ByMessageMasterDataId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrioritiesV2ByMessageMasterDataId(params, context) {
    return this.getPrioritiesV2ByMessageMasterDataId$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function FaultMasterDataApiV2PrioritiesService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FaultMasterDataApiV2PrioritiesService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FaultMasterDataApiV2PrioritiesService,
      factory: FaultMasterDataApiV2PrioritiesService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FaultMasterDataApiV2PrioritiesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getProcessedTelexByIdUsingGet(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getProcessedTelexByIdUsingGet.PATH, 'get');
  if (params) {
    rb.path('telexId', params.telexId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getProcessedTelexByIdUsingGet.PATH = '/faults/v1/processedtelexes/{telexId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getRawTelexByIdUsingGet(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getRawTelexByIdUsingGet.PATH, 'get');
  if (params) {
    rb.path('telexId', params.telexId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getRawTelexByIdUsingGet.PATH = '/faults/v1/rawtelexes/{telexId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getRepetitiveCalculationRules(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getRepetitiveCalculationRules.PATH, 'get');
  if (params) {
    rb.query('partnerId', params.partnerId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getRepetitiveCalculationRules.PATH = '/faults/v1/repetitive/rules';

/**
 * Fault Endpoints
 */
class FaultsApIsService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getProcessedTelexByIdUsingGet()` */
  static {
    this.GetProcessedTelexByIdUsingGetPath = '/faults/v1/processedtelexes/{telexId}';
  }
  /**
   * Get Processed Telex resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcessedTelexByIdUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessedTelexByIdUsingGet$Response(params, context) {
    return getProcessedTelexByIdUsingGet(this.http, this.rootUrl, params, context);
  }
  /**
   * Get Processed Telex resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProcessedTelexByIdUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessedTelexByIdUsingGet(params, context) {
    return this.getProcessedTelexByIdUsingGet$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getRawTelexByIdUsingGet()` */
  static {
    this.GetRawTelexByIdUsingGetPath = '/faults/v1/rawtelexes/{telexId}';
  }
  /**
   * Get Raw Telex resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRawTelexByIdUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRawTelexByIdUsingGet$Response(params, context) {
    return getRawTelexByIdUsingGet(this.http, this.rootUrl, params, context);
  }
  /**
   * Get Raw Telex resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRawTelexByIdUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRawTelexByIdUsingGet(params, context) {
    return this.getRawTelexByIdUsingGet$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getRepetitiveCalculationRules()` */
  static {
    this.GetRepetitiveCalculationRulesPath = '/faults/v1/repetitive/rules';
  }
  /**
   * Repetitive Calculation Rules Endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRepetitiveCalculationRules()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRepetitiveCalculationRules$Response(params, context) {
    return getRepetitiveCalculationRules(this.http, this.rootUrl, params, context);
  }
  /**
   * Repetitive Calculation Rules Endpoint.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRepetitiveCalculationRules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRepetitiveCalculationRules(params, context) {
    return this.getRepetitiveCalculationRules$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function FaultsApIsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FaultsApIsService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FaultsApIsService,
      factory: FaultsApIsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FaultsApIsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getFrontendFragment(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getFrontendFragment.PATH, 'get');
  if (params) {
    rb.path('type', params.type, {});
    rb.path('id', params.id, {});
    rb.header('app_id', params.app_id, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getFrontendFragment.PATH = '/frontend-fragments/{type}/{id}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getFrontendFragments(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getFrontendFragments.PATH, 'get');
  if (params) {
    rb.path('type', params.type, {});
    rb.header('app_id', params.app_id, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getFrontendFragments.PATH = '/frontend-fragments/{type}';
class FrontendFragmentService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getFrontendFragments()` */
  static {
    this.GetFrontendFragmentsPath = '/frontend-fragments/{type}';
  }
  /**
   * Returns all frontend fragments of a given type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFrontendFragments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFrontendFragments$Response(params, context) {
    return getFrontendFragments(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all frontend fragments of a given type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFrontendFragments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFrontendFragments(params, context) {
    return this.getFrontendFragments$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getFrontendFragment()` */
  static {
    this.GetFrontendFragmentPath = '/frontend-fragments/{type}/{id}';
  }
  /**
   * Returns a single frontend fragment by its type and id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFrontendFragment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFrontendFragment$Response(params, context) {
    return getFrontendFragment(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns a single frontend fragment by its type and id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFrontendFragment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFrontendFragment(params, context) {
    return this.getFrontendFragment$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function FrontendFragmentService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FrontendFragmentService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FrontendFragmentService,
      factory: FrontendFragmentService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FrontendFragmentService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function deleteApnsSubscription(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, deleteApnsSubscription.PATH, 'delete');
  if (params) {
    rb.path('deviceToken', params.deviceToken, {});
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
deleteApnsSubscription.PATH = '/notification-v2/apns-subscriptions/{deviceToken}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function saveApnsSubscription(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, saveApnsSubscription.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
saveApnsSubscription.PATH = '/notification-v2/apns-subscriptions';

/**
 * APNs subscription management
 */
class ApnsSubscriptionService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `saveApnsSubscription()` */
  static {
    this.SaveApnsSubscriptionPath = '/notification-v2/apns-subscriptions';
  }
  /**
   * Saves an APNs subscription.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveApnsSubscription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveApnsSubscription$Response(params, context) {
    return saveApnsSubscription(this.http, this.rootUrl, params, context);
  }
  /**
   * Saves an APNs subscription.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveApnsSubscription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveApnsSubscription(params, context) {
    return this.saveApnsSubscription$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `deleteApnsSubscription()` */
  static {
    this.DeleteApnsSubscriptionPath = '/notification-v2/apns-subscriptions/{deviceToken}';
  }
  /**
   * Deletes an APNs subscription.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApnsSubscription()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApnsSubscription$Response(params, context) {
    return deleteApnsSubscription(this.http, this.rootUrl, params, context);
  }
  /**
   * Deletes an APNs subscription.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteApnsSubscription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApnsSubscription(params, context) {
    return this.deleteApnsSubscription$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function ApnsSubscriptionService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ApnsSubscriptionService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ApnsSubscriptionService,
      factory: ApnsSubscriptionService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApnsSubscriptionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getCategoriesForUser(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getCategoriesForUser.PATH, 'get');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getCategoriesForUser.PATH = '/notification-v2/user-categories';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getUserDeliverySettings(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getUserDeliverySettings.PATH, 'get');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getUserDeliverySettings.PATH = '/notification-v2/delivery-settings';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function updateUserDeliverySettings(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, updateUserDeliverySettings.PATH, 'patch');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
updateUserDeliverySettings.PATH = '/notification-v2/delivery-settings';

/**
 * Settings for category delivery of a user
 */
class UserCategoryService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getUserDeliverySettings()` */
  static {
    this.GetUserDeliverySettingsPath = '/notification-v2/delivery-settings';
  }
  /**
   * Retrieves user-specific message category delivery settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserDeliverySettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserDeliverySettings$Response(params, context) {
    return getUserDeliverySettings(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieves user-specific message category delivery settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserDeliverySettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserDeliverySettings(params, context) {
    return this.getUserDeliverySettings$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `updateUserDeliverySettings()` */
  static {
    this.UpdateUserDeliverySettingsPath = '/notification-v2/delivery-settings';
  }
  /**
   * Modifies user-specific message category delivery settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserDeliverySettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserDeliverySettings$Response(params, context) {
    return updateUserDeliverySettings(this.http, this.rootUrl, params, context);
  }
  /**
   * Modifies user-specific message category delivery settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserDeliverySettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserDeliverySettings(params, context) {
    return this.updateUserDeliverySettings$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getCategoriesForUser()` */
  static {
    this.GetCategoriesForUserPath = '/notification-v2/user-categories';
  }
  /**
   * List categories visible for user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCategoriesForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoriesForUser$Response(params, context) {
    return getCategoriesForUser(this.http, this.rootUrl, params, context);
  }
  /**
   * List categories visible for user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCategoriesForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoriesForUser(params, context) {
    return this.getCategoriesForUser$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function UserCategoryService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UserCategoryService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserCategoryService,
      factory: UserCategoryService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserCategoryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getLatestInboxItems(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getLatestInboxItems.PATH, 'get');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getLatestInboxItems.PATH = '/notification-v2/inbox/message/latest';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getMessageCount(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getMessageCount.PATH, 'get');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getMessageCount.PATH = '/notification-v2/inbox/message/count';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function markAllAsRead(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, markAllAsRead.PATH, 'put');
  if (params) {}
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
markAllAsRead.PATH = '/notification-v2/inbox/message/read';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function markMessageAsRead(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, markMessageAsRead.PATH, 'put');
  if (params) {
    rb.path('messageId', params.messageId, {});
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
markMessageAsRead.PATH = '/notification-v2/inbox/message/{messageId}/read';
class InboxService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getMessageCount()` */
  static {
    this.GetMessageCountPath = '/notification-v2/inbox/message/count';
  }
  /**
   * Retrieve the total amount of unread messages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMessageCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageCount$Response(params, context) {
    return getMessageCount(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve the total amount of unread messages.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMessageCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageCount(params, context) {
    return this.getMessageCount$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getLatestInboxItems()` */
  static {
    this.GetLatestInboxItemsPath = '/notification-v2/inbox/message/latest';
  }
  /**
   * Retrieve the 10 latest inbox items.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestInboxItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestInboxItems$Response(params, context) {
    return getLatestInboxItems(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve the 10 latest inbox items.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestInboxItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestInboxItems(params, context) {
    return this.getLatestInboxItems$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `markAllAsRead()` */
  static {
    this.MarkAllAsReadPath = '/notification-v2/inbox/message/read';
  }
  /**
   * Mark all messages as read.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markAllAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAllAsRead$Response(params, context) {
    return markAllAsRead(this.http, this.rootUrl, params, context);
  }
  /**
   * Mark all messages as read.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markAllAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAllAsRead(params, context) {
    return this.markAllAsRead$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `markMessageAsRead()` */
  static {
    this.MarkMessageAsReadPath = '/notification-v2/inbox/message/{messageId}/read';
  }
  /**
   * Mark message as read.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markMessageAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  markMessageAsRead$Response(params, context) {
    return markMessageAsRead(this.http, this.rootUrl, params, context);
  }
  /**
   * Mark message as read.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markMessageAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markMessageAsRead(params, context) {
    return this.markMessageAsRead$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function InboxService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || InboxService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: InboxService,
      factory: InboxService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InboxService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function deleteWebPushSubscription(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, deleteWebPushSubscription.PATH, 'delete');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
deleteWebPushSubscription.PATH = '/notification-v2/webpush-subscriptions';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getWebPushConfig(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getWebPushConfig.PATH, 'get');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getWebPushConfig.PATH = '/notification-v2/webpush-config';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getWebPushSubscriptions(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getWebPushSubscriptions.PATH, 'get');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getWebPushSubscriptions.PATH = '/notification-v2/webpush-subscriptions';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function saveWebPushSubscription(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, saveWebPushSubscription.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
saveWebPushSubscription.PATH = '/notification-v2/webpush-subscriptions';

/**
 * Web push subscription management
 */
class WebPushSubscriptionService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getWebPushConfig()` */
  static {
    this.GetWebPushConfigPath = '/notification-v2/webpush-config';
  }
  /**
   * Returns configuration for browser web push.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWebPushConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebPushConfig$Response(params, context) {
    return getWebPushConfig(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns configuration for browser web push.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWebPushConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebPushConfig(params, context) {
    return this.getWebPushConfig$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getWebPushSubscriptions()` */
  static {
    this.GetWebPushSubscriptionsPath = '/notification-v2/webpush-subscriptions';
  }
  /**
   * Returns all active subscriptions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWebPushSubscriptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebPushSubscriptions$Response(params, context) {
    return getWebPushSubscriptions(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all active subscriptions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWebPushSubscriptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebPushSubscriptions(params, context) {
    return this.getWebPushSubscriptions$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `saveWebPushSubscription()` */
  static {
    this.SaveWebPushSubscriptionPath = '/notification-v2/webpush-subscriptions';
  }
  /**
   * Saves an active subscription.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveWebPushSubscription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWebPushSubscription$Response(params, context) {
    return saveWebPushSubscription(this.http, this.rootUrl, params, context);
  }
  /**
   * Saves an active subscription.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveWebPushSubscription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWebPushSubscription(params, context) {
    return this.saveWebPushSubscription$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `deleteWebPushSubscription()` */
  static {
    this.DeleteWebPushSubscriptionPath = '/notification-v2/webpush-subscriptions';
  }
  /**
   * Deletes an active subscription.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWebPushSubscription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteWebPushSubscription$Response(params, context) {
    return deleteWebPushSubscription(this.http, this.rootUrl, params, context);
  }
  /**
   * Deletes an active subscription.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteWebPushSubscription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteWebPushSubscription(params, context) {
    return this.deleteWebPushSubscription$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function WebPushSubscriptionService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || WebPushSubscriptionService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WebPushSubscriptionService,
      factory: WebPushSubscriptionService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WebPushSubscriptionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getUserSupportText(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getUserSupportText.PATH, 'get');
  if (params) {}
  return http.request(rb.build({
    responseType: 'text',
    accept: 'text/plain',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getUserSupportText.PATH = '/partner/user-support-text';
class PartnerService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getUserSupportText()` */
  static {
    this.GetUserSupportTextPath = '/partner/user-support-text';
  }
  /**
   * Gets the user-support-text of the users partner.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserSupportText()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserSupportText$Response(params, context) {
    return getUserSupportText(this.http, this.rootUrl, params, context);
  }
  /**
   * Gets the user-support-text of the users partner.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserSupportText$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserSupportText(params, context) {
    return this.getUserSupportText$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function PartnerService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PartnerService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PartnerService,
      factory: PartnerService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PartnerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getWorkOrders(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getWorkOrders.PATH, 'get');
  if (params) {
    rb.query('mongoIds', params.mongoIds, {});
    rb.query('partnerId', params.partnerId, {});
    rb.query('ataChapter', params.ataChapter, {});
    rb.query('ataSection', params.ataSection, {});
    rb.query('recordName', params.recordName, {});
    rb.query('entityId', params.entityId, {});
    rb.query('aircraftIds', params.aircraftIds, {});
    rb.query('partNum', params.partNum, {});
    rb.query('partSerialNum', params.partSerialNum, {});
    rb.query('typeOfDeferrals', params.typeOfDeferrals, {});
    rb.query('typeOfWorkOrder', params.typeOfWorkOrder, {});
    rb.query('dueDateFrom', params.dueDateFrom, {});
    rb.query('dueDateTo', params.dueDateTo, {});
    rb.query('openingDateFrom', params.openingDateFrom, {});
    rb.query('openingDateTo', params.openingDateTo, {});
    rb.query('closingDateFrom', params.closingDateFrom, {});
    rb.query('closingDateTo', params.closingDateTo, {});
    rb.query('deferralDateFrom', params.deferralDateFrom, {});
    rb.query('deferralDateTo', params.deferralDateTo, {});
    rb.query('modifiedAfter', params.modifiedAfter, {});
    rb.query('textSearch', params.textSearch, {});
    rb.query('labelGroup', params.labelGroup, {});
    rb.query('labels', params.labels, {});
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
    rb.query('openedOnly', params.openedOnly, {});
    rb.query('closedOnly', params.closedOnly, {});
    rb.query('include', params.include, {});
    rb.query('countOnly', params.countOnly, {});
    rb.query('grouping', params.grouping, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getWorkOrders.PATH = '/workorders-v2';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getWorkOrders1(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getWorkOrders1.PATH, 'get');
  if (params) {
    rb.path('partnerId', params.partnerId, {});
    rb.path('workorderId', params.workorderId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getWorkOrders1.PATH = '/partner/{partnerId}/workorder/{workorderId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getWorkPackages(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getWorkPackages.PATH, 'get');
  if (params) {
    rb.query('mongoIds', params.mongoIds, {});
    rb.query('partnerId', params.partnerId, {});
    rb.query('recordName', params.recordName, {});
    rb.query('aircraftIds', params.aircraftIds, {});
    rb.query('startDateFrom', params.startDateFrom, {});
    rb.query('startDateTo', params.startDateTo, {});
    rb.query('endDateFrom', params.endDateFrom, {});
    rb.query('endDateTo', params.endDateTo, {});
    rb.query('textSearch', params.textSearch, {});
    rb.query('status', params.status, {});
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getWorkPackages.PATH = '/workpackages-v2';

/**
 * Work Order Service V2
 */
class WorkOrderServiceV2Service extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getWorkOrders1()` */
  static {
    this.GetWorkOrders1Path = '/partner/{partnerId}/workorder/{workorderId}';
  }
  /**
   * Get response with a single workorder. Basically for frontend requests. No special role required, just ar_ROLE to access aircraft.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkOrders1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkOrders1$Response(params, context) {
    return getWorkOrders1(this.http, this.rootUrl, params, context);
  }
  /**
   * Get response with a single workorder. Basically for frontend requests. No special role required, just ar_ROLE to access aircraft.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkOrders1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkOrders1(params, context) {
    return this.getWorkOrders1$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getWorkOrders()` */
  static {
    this.GetWorkOrdersPath = '/workorders-v2';
  }
  /**
   * Get response with workorder list. Role retrieve_workorder required.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkOrders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkOrders$Response(params, context) {
    return getWorkOrders(this.http, this.rootUrl, params, context);
  }
  /**
   * Get response with workorder list. Role retrieve_workorder required.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkOrders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkOrders(params, context) {
    return this.getWorkOrders$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getWorkPackages()` */
  static {
    this.GetWorkPackagesPath = '/workpackages-v2';
  }
  /**
   * Get response with workpackage list. Role retrieve_workorder required.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkPackages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkPackages$Response(params, context) {
    return getWorkPackages(this.http, this.rootUrl, params, context);
  }
  /**
   * Get response with workpackage list. Role retrieve_workorder required.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkPackages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkPackages(params, context) {
    return this.getWorkPackages$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function WorkOrderServiceV2Service_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || WorkOrderServiceV2Service)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WorkOrderServiceV2Service,
      factory: WorkOrderServiceV2Service.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WorkOrderServiceV2Service, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getPermittedAssetIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getPermittedAssetIds.PATH, 'get');
  if (params) {
    rb.path('assetType', params.assetType, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getPermittedAssetIds.PATH = '/permission/asset/{assetType}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getPermittedForAssetId(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getPermittedForAssetId.PATH, 'get');
  if (params) {
    rb.path('assetType', params.assetType, {});
    rb.path('assetId', params.assetId, {});
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
getPermittedForAssetId.PATH = '/permission/asset/{assetType}/{assetId}';
class PermissionService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getPermittedAssetIds()` */
  static {
    this.GetPermittedAssetIdsPath = '/permission/asset/{assetType}';
  }
  /**
   * Returns assets permitted to the user for a given asset type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPermittedAssetIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermittedAssetIds$Response(params, context) {
    return getPermittedAssetIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns assets permitted to the user for a given asset type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPermittedAssetIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermittedAssetIds(params, context) {
    return this.getPermittedAssetIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getPermittedForAssetId()` */
  static {
    this.GetPermittedForAssetIdPath = '/permission/asset/{assetType}/{assetId}';
  }
  /**
   * Checks the user's permissions on a given asset.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPermittedForAssetId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermittedForAssetId$Response(params, context) {
    return getPermittedForAssetId(this.http, this.rootUrl, params, context);
  }
  /**
   * Checks the user's permissions on a given asset.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPermittedForAssetId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermittedForAssetId(params, context) {
    return this.getPermittedForAssetId$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function PermissionService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PermissionService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PermissionService,
      factory: PermissionService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PermissionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getProcessEngines(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getProcessEngines.PATH, 'get');
  if (params) {
    rb.query('id', params.id, {
      "style": "form",
      "explode": true
    });
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
    rb.header('app_id', params.app_id, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getProcessEngines.PATH = '/process-engines';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getProcessStarters(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getProcessStarters.PATH, 'get');
  if (params) {
    rb.query('id', params.id, {
      "style": "form",
      "explode": true
    });
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
    rb.header('app_id', params.app_id, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getProcessStarters.PATH = '/process-starters';
class ProcessService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getProcessEngines()` */
  static {
    this.GetProcessEnginesPath = '/process-engines';
  }
  /**
   * Returns all or filtered process engines.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcessEngines()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessEngines$Response(params, context) {
    return getProcessEngines(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all or filtered process engines.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProcessEngines$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessEngines(params, context) {
    return this.getProcessEngines$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getProcessStarters()` */
  static {
    this.GetProcessStartersPath = '/process-starters';
  }
  /**
   * Returns all or filtered process starters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcessStarters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessStarters$Response(params, context) {
    return getProcessStarters(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all or filtered process starters.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProcessStarters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessStarters(params, context) {
    return this.getProcessStarters$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function ProcessService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ProcessService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ProcessService,
      factory: ProcessService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProcessService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getReleaseNotesAttachment(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getReleaseNotesAttachment.PATH, 'get');
  if (params) {
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getReleaseNotesAttachment.PATH = '/release-notes/attachments/{attachmentId}';
class ReleaseNotesAttachmentsService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getReleaseNotesAttachment()` */
  static {
    this.GetReleaseNotesAttachmentPath = '/release-notes/attachments/{attachmentId}';
  }
  /**
   * Gets an attachment by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReleaseNotesAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReleaseNotesAttachment$Response(params, context) {
    return getReleaseNotesAttachment(this.http, this.rootUrl, params, context);
  }
  /**
   * Gets an attachment by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReleaseNotesAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReleaseNotesAttachment(params, context) {
    return this.getReleaseNotesAttachment$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function ReleaseNotesAttachmentsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ReleaseNotesAttachmentsService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ReleaseNotesAttachmentsService,
      factory: ReleaseNotesAttachmentsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ReleaseNotesAttachmentsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getLatestReleaseNotesEntry(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getLatestReleaseNotesEntry.PATH, 'get');
  if (params) {
    rb.path('applicationId', params.applicationId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getLatestReleaseNotesEntry.PATH = '/release-notes/by-application/{applicationId}/latest';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function markApplicationReleaseNotesAsRead(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, markApplicationReleaseNotesAsRead.PATH, 'put');
  if (params) {
    rb.path('applicationId', params.applicationId, {});
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
markApplicationReleaseNotesAsRead.PATH = '/release-notes/by-application/{applicationId}/mark-as-read';
class ReleaseNotesService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getLatestReleaseNotesEntry()` */
  static {
    this.GetLatestReleaseNotesEntryPath = '/release-notes/by-application/{applicationId}/latest';
  }
  /**
   * Gets the latest release notes entry by application.
   * "Latest" means the most recent published release notes entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestReleaseNotesEntry()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestReleaseNotesEntry$Response(params, context) {
    return getLatestReleaseNotesEntry(this.http, this.rootUrl, params, context);
  }
  /**
   * Gets the latest release notes entry by application.
   * "Latest" means the most recent published release notes entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestReleaseNotesEntry$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestReleaseNotesEntry(params, context) {
    return this.getLatestReleaseNotesEntry$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `markApplicationReleaseNotesAsRead()` */
  static {
    this.MarkApplicationReleaseNotesAsReadPath = '/release-notes/by-application/{applicationId}/mark-as-read';
  }
  /**
   * Marks release notes for an application as read up to the point of now.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markApplicationReleaseNotesAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  markApplicationReleaseNotesAsRead$Response(params, context) {
    return markApplicationReleaseNotesAsRead(this.http, this.rootUrl, params, context);
  }
  /**
   * Marks release notes for an application as read up to the point of now.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markApplicationReleaseNotesAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markApplicationReleaseNotesAsRead(params, context) {
    return this.markApplicationReleaseNotesAsRead$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function ReleaseNotesService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ReleaseNotesService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ReleaseNotesService,
      factory: ReleaseNotesService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ReleaseNotesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function addAttachment(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, addAttachment.PATH, 'post');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.body(params.body, 'multipart/form-data');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
addAttachment.PATH = '/remarks/{remarkId}/attachments';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function addRemark(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, addRemark.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
addRemark.PATH = '/remarks';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function deleteRemark(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, deleteRemark.PATH, 'delete');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
deleteRemark.PATH = '/remarks/{remarkId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function downloadAttachment$Any(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, downloadAttachment$Any.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'application/octet-stream',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
downloadAttachment$Any.PATH = '/remarks/{remarkId}/attachments/{attachmentId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function downloadAttachment$Jpeg(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, downloadAttachment$Jpeg.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'image/jpeg',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
downloadAttachment$Jpeg.PATH = '/remarks/{remarkId}/attachments/{attachmentId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function downloadAttachment$Json(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, downloadAttachment$Json.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
downloadAttachment$Json.PATH = '/remarks/{remarkId}/attachments/{attachmentId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function downloadAttachment$Pdf(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, downloadAttachment$Pdf.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'application/pdf',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
downloadAttachment$Pdf.PATH = '/remarks/{remarkId}/attachments/{attachmentId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function downloadAttachment$Png(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, downloadAttachment$Png.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'image/png',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
downloadAttachment$Png.PATH = '/remarks/{remarkId}/attachments/{attachmentId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function downloadAttachmentPreview$Any(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, downloadAttachmentPreview$Any.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'application/octet-stream',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
downloadAttachmentPreview$Any.PATH = '/remarks/{remarkId}/attachments/{attachmentId}/preview';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function downloadAttachmentPreview$Jpeg(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, downloadAttachmentPreview$Jpeg.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'image/jpeg',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
downloadAttachmentPreview$Jpeg.PATH = '/remarks/{remarkId}/attachments/{attachmentId}/preview';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function downloadAttachmentPreview$Json(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, downloadAttachmentPreview$Json.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
downloadAttachmentPreview$Json.PATH = '/remarks/{remarkId}/attachments/{attachmentId}/preview';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function downloadAttachmentPreview$Png(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, downloadAttachmentPreview$Png.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'image/png',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
downloadAttachmentPreview$Png.PATH = '/remarks/{remarkId}/attachments/{attachmentId}/preview';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function downloadAttachmentThumbnail$Any(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, downloadAttachmentThumbnail$Any.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'application/octet-stream',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
downloadAttachmentThumbnail$Any.PATH = '/remarks/{remarkId}/attachments/{attachmentId}/thumbnail';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function downloadAttachmentThumbnail$Jpeg(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, downloadAttachmentThumbnail$Jpeg.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'image/jpeg',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
downloadAttachmentThumbnail$Jpeg.PATH = '/remarks/{remarkId}/attachments/{attachmentId}/thumbnail';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function downloadAttachmentThumbnail$Json(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, downloadAttachmentThumbnail$Json.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
downloadAttachmentThumbnail$Json.PATH = '/remarks/{remarkId}/attachments/{attachmentId}/thumbnail';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function downloadAttachmentThumbnail$Png(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, downloadAttachmentThumbnail$Png.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'image/png',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
downloadAttachmentThumbnail$Png.PATH = '/remarks/{remarkId}/attachments/{attachmentId}/thumbnail';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAttachments(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAttachments.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAttachments.PATH = '/remarks/{remarkId}/attachments';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getConfigWebsocket(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getConfigWebsocket.PATH, 'get');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getConfigWebsocket.PATH = '/remarks-config';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getRemark(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getRemark.PATH, 'get');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getRemark.PATH = '/remarks/{remarkId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function purgeRemarks(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, purgeRemarks.PATH, 'delete');
  if (params) {}
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
purgeRemarks.PATH = '/remarks/purge';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function queryRemarks(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, queryRemarks.PATH, 'get');
  if (params) {
    rb.query('type', params.type, {});
    rb.query('identifier', params.identifier, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
queryRemarks.PATH = '/remarks';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function queryRemarksCountForEntitiesUpdatedSince(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, queryRemarksCountForEntitiesUpdatedSince.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
queryRemarksCountForEntitiesUpdatedSince.PATH = '/remarks/search/count';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function searchRemarks(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, searchRemarks.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
searchRemarks.PATH = '/remarks/search';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function searchRemarksByEntityType(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, searchRemarksByEntityType.PATH, 'post');
  if (params) {
    rb.path('type', params.type, {});
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
searchRemarksByEntityType.PATH = '/remarks/search/types/{type}/identifiers';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function updateRemark(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, updateRemark.PATH, 'put');
  if (params) {
    rb.path('remarkId', params.remarkId, {});
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
updateRemark.PATH = '/remarks/{remarkId}';

/**
 * Remarks operations
 */
class RemarksService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `queryRemarks()` */
  static {
    this.QueryRemarksPath = '/remarks';
  }
  /**
   * Queries all remarks for a specified entity.
   *
   * Query remarks for a given entity. Checks for entityPermission and partners involved
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryRemarks()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryRemarks$Response(params, context) {
    return queryRemarks(this.http, this.rootUrl, params, context);
  }
  /**
   * Queries all remarks for a specified entity.
   *
   * Query remarks for a given entity. Checks for entityPermission and partners involved
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `queryRemarks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryRemarks(params, context) {
    return this.queryRemarks$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `addRemark()` */
  static {
    this.AddRemarkPath = '/remarks';
  }
  /**
   * Add a new remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRemark()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRemark$Response(params, context) {
    return addRemark(this.http, this.rootUrl, params, context);
  }
  /**
   * Add a new remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRemark$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRemark(params, context) {
    return this.addRemark$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getConfigWebsocket()` */
  static {
    this.GetConfigWebsocketPath = '/remarks-config';
  }
  /**
   * Get configuration data of websocket endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfigWebsocket()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigWebsocket$Response(params, context) {
    return getConfigWebsocket(this.http, this.rootUrl, params, context);
  }
  /**
   * Get configuration data of websocket endpoint.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConfigWebsocket$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigWebsocket(params, context) {
    return this.getConfigWebsocket$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `purgeRemarks()` */
  static {
    this.PurgeRemarksPath = '/remarks/purge';
  }
  /**
   * Purges all remarks of the logged in user. The user must be allow-listed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `purgeRemarks()` instead.
   *
   * This method doesn't expect any request body.
   */
  purgeRemarks$Response(params, context) {
    return purgeRemarks(this.http, this.rootUrl, params, context);
  }
  /**
   * Purges all remarks of the logged in user. The user must be allow-listed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `purgeRemarks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  purgeRemarks(params, context) {
    return this.purgeRemarks$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `searchRemarks()` */
  static {
    this.SearchRemarksPath = '/remarks/search';
  }
  /**
   * Search for remarks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchRemarks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchRemarks$Response(params, context) {
    return searchRemarks(this.http, this.rootUrl, params, context);
  }
  /**
   * Search for remarks.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchRemarks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchRemarks(params, context) {
    return this.searchRemarks$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `queryRemarksCountForEntitiesUpdatedSince()` */
  static {
    this.QueryRemarksCountForEntitiesUpdatedSincePath = '/remarks/search/count';
  }
  /**
   * Query remarks number for multiple entities updated or created since given timestamps. Checks for entityPermission and partners involved.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryRemarksCountForEntitiesUpdatedSince()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryRemarksCountForEntitiesUpdatedSince$Response(params, context) {
    return queryRemarksCountForEntitiesUpdatedSince(this.http, this.rootUrl, params, context);
  }
  /**
   * Query remarks number for multiple entities updated or created since given timestamps. Checks for entityPermission and partners involved.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `queryRemarksCountForEntitiesUpdatedSince$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryRemarksCountForEntitiesUpdatedSince(params, context) {
    return this.queryRemarksCountForEntitiesUpdatedSince$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `searchRemarksByEntityType()` */
  static {
    this.SearchRemarksByEntityTypePath = '/remarks/search/types/{type}/identifiers';
  }
  /**
   * Specialized search endpoint for searching by entity of specific type. Related entities are not searchable via this endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchRemarksByEntityType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchRemarksByEntityType$Response(params, context) {
    return searchRemarksByEntityType(this.http, this.rootUrl, params, context);
  }
  /**
   * Specialized search endpoint for searching by entity of specific type. Related entities are not searchable via this endpoint.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchRemarksByEntityType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchRemarksByEntityType(params, context) {
    return this.searchRemarksByEntityType$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getRemark()` */
  static {
    this.GetRemarkPath = '/remarks/{remarkId}';
  }
  /**
   * Get specific remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRemark()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRemark$Response(params, context) {
    return getRemark(this.http, this.rootUrl, params, context);
  }
  /**
   * Get specific remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRemark$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRemark(params, context) {
    return this.getRemark$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `updateRemark()` */
  static {
    this.UpdateRemarkPath = '/remarks/{remarkId}';
  }
  /**
   * Update a specific remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRemark()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRemark$Response(params, context) {
    return updateRemark(this.http, this.rootUrl, params, context);
  }
  /**
   * Update a specific remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRemark$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRemark(params, context) {
    return this.updateRemark$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `deleteRemark()` */
  static {
    this.DeleteRemarkPath = '/remarks/{remarkId}';
  }
  /**
   * Removes a specific remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRemark()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRemark$Response(params, context) {
    return deleteRemark(this.http, this.rootUrl, params, context);
  }
  /**
   * Removes a specific remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRemark$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRemark(params, context) {
    return this.deleteRemark$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getAttachments()` */
  static {
    this.GetAttachmentsPath = '/remarks/{remarkId}/attachments';
  }
  /**
   * Get metadata of all attachments for a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachments$Response(params, context) {
    return getAttachments(this.http, this.rootUrl, params, context);
  }
  /**
   * Get metadata of all attachments for a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachments(params, context) {
    return this.getAttachments$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `addAttachment()` */
  static {
    this.AddAttachmentPath = '/remarks/{remarkId}/attachments';
  }
  /**
   * Add attachment to an existing remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addAttachment$Response(params, context) {
    return addAttachment(this.http, this.rootUrl, params, context);
  }
  /**
   * Add attachment to an existing remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addAttachment(params, context) {
    return this.addAttachment$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `downloadAttachment()` */
  static {
    this.DownloadAttachmentPath = '/remarks/{remarkId}/attachments/{attachmentId}';
  }
  /**
   * Get a specific attachment of a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachment$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment$Pdf$Response(params, context) {
    return downloadAttachment$Pdf(this.http, this.rootUrl, params, context);
  }
  /**
   * Get a specific attachment of a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachment$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment$Pdf(params, context) {
    return this.downloadAttachment$Pdf$Response(params, context).pipe(map(r => r.body));
  }
  /**
   * Get a specific attachment of a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachment$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment$Png$Response(params, context) {
    return downloadAttachment$Png(this.http, this.rootUrl, params, context);
  }
  /**
   * Get a specific attachment of a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachment$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment$Png(params, context) {
    return this.downloadAttachment$Png$Response(params, context).pipe(map(r => r.body));
  }
  /**
   * Get a specific attachment of a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachment$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment$Jpeg$Response(params, context) {
    return downloadAttachment$Jpeg(this.http, this.rootUrl, params, context);
  }
  /**
   * Get a specific attachment of a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachment$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment$Jpeg(params, context) {
    return this.downloadAttachment$Jpeg$Response(params, context).pipe(map(r => r.body));
  }
  /**
   * Get a specific attachment of a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachment$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment$Any$Response(params, context) {
    return downloadAttachment$Any(this.http, this.rootUrl, params, context);
  }
  /**
   * Get a specific attachment of a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachment$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment$Any(params, context) {
    return this.downloadAttachment$Any$Response(params, context).pipe(map(r => r.body));
  }
  /**
   * Get a specific attachment of a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachment$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment$Json$Response(params, context) {
    return downloadAttachment$Json(this.http, this.rootUrl, params, context);
  }
  /**
   * Get a specific attachment of a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachment$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment$Json(params, context) {
    return this.downloadAttachment$Json$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `downloadAttachmentPreview()` */
  static {
    this.DownloadAttachmentPreviewPath = '/remarks/{remarkId}/attachments/{attachmentId}/preview';
  }
  /**
   * Get preview for a specific attachment of a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachmentPreview$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentPreview$Png$Response(params, context) {
    return downloadAttachmentPreview$Png(this.http, this.rootUrl, params, context);
  }
  /**
   * Get preview for a specific attachment of a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachmentPreview$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentPreview$Png(params, context) {
    return this.downloadAttachmentPreview$Png$Response(params, context).pipe(map(r => r.body));
  }
  /**
   * Get preview for a specific attachment of a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachmentPreview$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentPreview$Jpeg$Response(params, context) {
    return downloadAttachmentPreview$Jpeg(this.http, this.rootUrl, params, context);
  }
  /**
   * Get preview for a specific attachment of a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachmentPreview$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentPreview$Jpeg(params, context) {
    return this.downloadAttachmentPreview$Jpeg$Response(params, context).pipe(map(r => r.body));
  }
  /**
   * Get preview for a specific attachment of a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachmentPreview$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentPreview$Any$Response(params, context) {
    return downloadAttachmentPreview$Any(this.http, this.rootUrl, params, context);
  }
  /**
   * Get preview for a specific attachment of a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachmentPreview$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentPreview$Any(params, context) {
    return this.downloadAttachmentPreview$Any$Response(params, context).pipe(map(r => r.body));
  }
  /**
   * Get preview for a specific attachment of a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachmentPreview$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentPreview$Json$Response(params, context) {
    return downloadAttachmentPreview$Json(this.http, this.rootUrl, params, context);
  }
  /**
   * Get preview for a specific attachment of a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachmentPreview$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentPreview$Json(params, context) {
    return this.downloadAttachmentPreview$Json$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `downloadAttachmentThumbnail()` */
  static {
    this.DownloadAttachmentThumbnailPath = '/remarks/{remarkId}/attachments/{attachmentId}/thumbnail';
  }
  /**
   * Get thumbnail for a specific attachment of a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachmentThumbnail$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentThumbnail$Png$Response(params, context) {
    return downloadAttachmentThumbnail$Png(this.http, this.rootUrl, params, context);
  }
  /**
   * Get thumbnail for a specific attachment of a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachmentThumbnail$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentThumbnail$Png(params, context) {
    return this.downloadAttachmentThumbnail$Png$Response(params, context).pipe(map(r => r.body));
  }
  /**
   * Get thumbnail for a specific attachment of a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachmentThumbnail$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentThumbnail$Jpeg$Response(params, context) {
    return downloadAttachmentThumbnail$Jpeg(this.http, this.rootUrl, params, context);
  }
  /**
   * Get thumbnail for a specific attachment of a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachmentThumbnail$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentThumbnail$Jpeg(params, context) {
    return this.downloadAttachmentThumbnail$Jpeg$Response(params, context).pipe(map(r => r.body));
  }
  /**
   * Get thumbnail for a specific attachment of a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachmentThumbnail$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentThumbnail$Any$Response(params, context) {
    return downloadAttachmentThumbnail$Any(this.http, this.rootUrl, params, context);
  }
  /**
   * Get thumbnail for a specific attachment of a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachmentThumbnail$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentThumbnail$Any(params, context) {
    return this.downloadAttachmentThumbnail$Any$Response(params, context).pipe(map(r => r.body));
  }
  /**
   * Get thumbnail for a specific attachment of a remark.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachmentThumbnail$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentThumbnail$Json$Response(params, context) {
    return downloadAttachmentThumbnail$Json(this.http, this.rootUrl, params, context);
  }
  /**
   * Get thumbnail for a specific attachment of a remark.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachmentThumbnail$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentThumbnail$Json(params, context) {
    return this.downloadAttachmentThumbnail$Json$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function RemarksService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || RemarksService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: RemarksService,
      factory: RemarksService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RemarksService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function resolveSubjectEndpoint(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, resolveSubjectEndpoint.PATH, 'get');
  if (params) {
    rb.path('subject', params.subject, {});
    rb.path('trackingId', params.trackingId, {});
    rb.header('app_id', params.app_id, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
resolveSubjectEndpoint.PATH = '/subject-endpoints/{subject}/{trackingId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function resolveSubjectEndpointsWithParameters(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, resolveSubjectEndpointsWithParameters.PATH, 'get');
  if (params) {
    rb.path('subject', params.subject, {});
    rb.header('app_id', params.app_id, {});
    rb.query('parameters', params.parameters, {
      "style": "form",
      "explode": true
    });
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
resolveSubjectEndpointsWithParameters.PATH = '/subject-endpoints/{subject}';
class SubjectService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `resolveSubjectEndpointsWithParameters()` */
  static {
    this.ResolveSubjectEndpointsWithParametersPath = '/subject-endpoints/{subject}';
  }
  /**
   * Resolves the URL for the subject with given type without any parameters.
   *
   * Resolves the URL for the subject with given type without any parameters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resolveSubjectEndpointsWithParameters()` instead.
   *
   * This method doesn't expect any request body.
   */
  resolveSubjectEndpointsWithParameters$Response(params, context) {
    return resolveSubjectEndpointsWithParameters(this.http, this.rootUrl, params, context);
  }
  /**
   * Resolves the URL for the subject with given type without any parameters.
   *
   * Resolves the URL for the subject with given type without any parameters.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resolveSubjectEndpointsWithParameters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resolveSubjectEndpointsWithParameters(params, context) {
    return this.resolveSubjectEndpointsWithParameters$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `resolveSubjectEndpoint()` */
  static {
    this.ResolveSubjectEndpointPath = '/subject-endpoints/{subject}/{trackingId}';
  }
  /**
   * Resolves the URL the subject with given type and tracking id.
   *
   * Resolves the URL the subject with given type and tracking id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resolveSubjectEndpoint()` instead.
   *
   * This method doesn't expect any request body.
   */
  resolveSubjectEndpoint$Response(params, context) {
    return resolveSubjectEndpoint(this.http, this.rootUrl, params, context);
  }
  /**
   * Resolves the URL the subject with given type and tracking id.
   *
   * Resolves the URL the subject with given type and tracking id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resolveSubjectEndpoint$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resolveSubjectEndpoint(params, context) {
    return this.resolveSubjectEndpoint$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function SubjectService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SubjectService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SubjectService,
      factory: SubjectService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SubjectService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function createTelexUplinkRequest(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, createTelexUplinkRequest.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
createTelexUplinkRequest.PATH = '/telex-uplink/v2/requests';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getReports(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getReports.PATH, 'get');
  if (params) {
    rb.query('aircraftId', params.aircraftId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getReports.PATH = '/telex-uplink/v2/reports';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getReportsAircraft(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getReportsAircraft.PATH, 'get');
  if (params) {
    rb.query('aircraftIds', params.aircraftIds, {
      "style": "form",
      "explode": false
    });
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getReportsAircraft.PATH = '/telex-uplink/v2/reports/aircraft';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getTelexUplinkRequests(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getTelexUplinkRequests.PATH, 'get');
  if (params) {
    rb.query('aircraftIds', params.aircraftIds, {
      "style": "form",
      "explode": false
    });
    rb.query('from', params.from, {});
    rb.query('to', params.to, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getTelexUplinkRequests.PATH = '/telex-uplink/v2/requests';

/**
 * Managing telex uplink requests.
 */
class TelexUplinkService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getReports()` */
  static {
    this.GetReportsPath = '/telex-uplink/v2/reports';
  }
  /**
   * Returns all enabled reports matching the given query filter.
   *
   * Returns all reports matching the given query filter.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReports$Response(params, context) {
    return getReports(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all enabled reports matching the given query filter.
   *
   * Returns all reports matching the given query filter.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReports(params, context) {
    return this.getReports$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getReportsAircraft()` */
  static {
    this.GetReportsAircraftPath = '/telex-uplink/v2/reports/aircraft';
  }
  /**
   * Returns all enabled reports grouped by aircraft.
   *
   * Returns all enabled reports grouped by aircraft.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportsAircraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportsAircraft$Response(params, context) {
    return getReportsAircraft(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all enabled reports grouped by aircraft.
   *
   * Returns all enabled reports grouped by aircraft.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReportsAircraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportsAircraft(params, context) {
    return this.getReportsAircraft$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getTelexUplinkRequests()` */
  static {
    this.GetTelexUplinkRequestsPath = '/telex-uplink/v2/requests';
  }
  /**
   * Returns all telex uplink requests matching the given criteria.
   *
   * Returns all telex uplink requests matching the given criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelexUplinkRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelexUplinkRequests$Response(params, context) {
    return getTelexUplinkRequests(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all telex uplink requests matching the given criteria.
   *
   * Returns all telex uplink requests matching the given criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelexUplinkRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelexUplinkRequests(params, context) {
    return this.getTelexUplinkRequests$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `createTelexUplinkRequest()` */
  static {
    this.CreateTelexUplinkRequestPath = '/telex-uplink/v2/requests';
  }
  /**
   * Creates a telex uplink request.
   *
   * Creates a telex uplink request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTelexUplinkRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTelexUplinkRequest$Response(params, context) {
    return createTelexUplinkRequest(this.http, this.rootUrl, params, context);
  }
  /**
   * Creates a telex uplink request.
   *
   * Creates a telex uplink request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTelexUplinkRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTelexUplinkRequest(params, context) {
    return this.createTelexUplinkRequest$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function TelexUplinkService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TelexUplinkService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TelexUplinkService,
      factory: TelexUplinkService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TelexUplinkService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getSnapshotReports(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getSnapshotReports.PATH, 'get');
  if (params) {
    rb.path('telexId', params.telexId, {});
    rb.query('formats', params.formats, {
      "style": "form",
      "explode": false
    });
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getSnapshotReports.PATH = '/telex/acms/body/{telexId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getTelexDownload(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getTelexDownload.PATH, 'get');
  if (params) {
    rb.path('telexId', params.telexId, {});
  }
  return http.request(rb.build({
    responseType: 'blob',
    accept: 'application/octet-stream',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getTelexDownload.PATH = '/telex/download/{telexId}';
class AcmsService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getSnapshotReports()` */
  static {
    this.GetSnapshotReportsPath = '/telex/acms/body/{telexId}';
  }
  /**
   * Returns the layouts for given telex id in different formats.
   *
   * Returns the layouts for given telex id in different formats
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSnapshotReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSnapshotReports$Response(params, context) {
    return getSnapshotReports(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns the layouts for given telex id in different formats.
   *
   * Returns the layouts for given telex id in different formats
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSnapshotReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSnapshotReports(params, context) {
    return this.getSnapshotReports$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getTelexDownload()` */
  static {
    this.GetTelexDownloadPath = '/telex/download/{telexId}';
  }
  /**
   * Returns the given telex id as an octet stream.
   *
   * Returns the given telex id as an octet stream
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelexDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelexDownload$Response(params, context) {
    return getTelexDownload(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns the given telex id as an octet stream.
   *
   * Returns the given telex id as an octet stream
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelexDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelexDownload(params, context) {
    return this.getTelexDownload$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function AcmsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AcmsService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AcmsService,
      factory: AcmsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AcmsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getSnapshotHeaderInfoByAircraft(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getSnapshotHeaderInfoByAircraft.PATH, 'get');
  if (params) {
    rb.query('aircraftIds', params.aircraftIds, {});
    rb.query('from', params.from, {});
    rb.query('to', params.to, {});
    rb.query('excludeFields', params.excludeFields, {});
    rb.query('timestampField', params.timestampField, {});
    rb.query('messageTypes', params.messageTypes, {});
    rb.query('triggerCodes', params.triggerCodes, {});
    rb.query('contentCategory', params.contentCategory, {});
    rb.query('smi', params.smi, {});
    rb.query('limit', params.limit, {});
    rb.query('page', params.page, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getSnapshotHeaderInfoByAircraft.PATH = '/telex/acms/headers';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getSnapshotHeaderInfoByTelexId(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getSnapshotHeaderInfoByTelexId.PATH, 'get');
  if (params) {
    rb.path('telexId', params.telexId, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getSnapshotHeaderInfoByTelexId.PATH = '/telex/acms/headers/{telexId}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function searchSnapshotTelexHeaderPriorities(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, searchSnapshotTelexHeaderPriorities.PATH, 'post');
  if (params) {
    rb.query('filterAttributes', params.filterAttributes, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
searchSnapshotTelexHeaderPriorities.PATH = '/telex/acms/headers/search/priorities';

/**
 * managing telex header information
 */
class SnapshotApiService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getSnapshotHeaderInfoByAircraft()` */
  static {
    this.GetSnapshotHeaderInfoByAircraftPath = '/telex/acms/headers';
  }
  /**
   * Returns header information of telexes filtered by aircraft and time ranges. Null-Values will not be rendered into the json
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSnapshotHeaderInfoByAircraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSnapshotHeaderInfoByAircraft$Response(params, context) {
    return getSnapshotHeaderInfoByAircraft(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns header information of telexes filtered by aircraft and time ranges. Null-Values will not be rendered into the json
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSnapshotHeaderInfoByAircraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSnapshotHeaderInfoByAircraft(params, context) {
    return this.getSnapshotHeaderInfoByAircraft$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `searchSnapshotTelexHeaderPriorities()` */
  static {
    this.SearchSnapshotTelexHeaderPrioritiesPath = '/telex/acms/headers/search/priorities';
  }
  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchSnapshotTelexHeaderPriorities()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSnapshotTelexHeaderPriorities$Response(params, context) {
    return searchSnapshotTelexHeaderPriorities(this.http, this.rootUrl, params, context);
  }
  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchSnapshotTelexHeaderPriorities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSnapshotTelexHeaderPriorities(params, context) {
    return this.searchSnapshotTelexHeaderPriorities$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getSnapshotHeaderInfoByTelexId()` */
  static {
    this.GetSnapshotHeaderInfoByTelexIdPath = '/telex/acms/headers/{telexId}';
  }
  /**
   * Returns header information of a telex given in the path. Null-Values will not be rendered into the json
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSnapshotHeaderInfoByTelexId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSnapshotHeaderInfoByTelexId$Response(params, context) {
    return getSnapshotHeaderInfoByTelexId(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns header information of a telex given in the path. Null-Values will not be rendered into the json
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSnapshotHeaderInfoByTelexId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSnapshotHeaderInfoByTelexId(params, context) {
    return this.getSnapshotHeaderInfoByTelexId$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function SnapshotApiService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SnapshotApiService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SnapshotApiService,
      factory: SnapshotApiService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SnapshotApiService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getSnapshotMasterdataReports(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getSnapshotMasterdataReports.PATH, 'get');
  if (params) {
    rb.query('aircraftId', params.aircraftId, {
      "style": "form",
      "explode": false
    });
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getSnapshotMasterdataReports.PATH = '/telex/acms/masterdata';

/**
 * Snapshot-Masterdata
 */
class SnapshotMasterdataService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getSnapshotMasterdataReports()` */
  static {
    this.GetSnapshotMasterdataReportsPath = '/telex/acms/masterdata';
  }
  /**
   * Returns all configured reports and its name by aircraft.
   *
   * Returns configured reports with name and number for a given aircraft
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSnapshotMasterdataReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSnapshotMasterdataReports$Response(params, context) {
    return getSnapshotMasterdataReports(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns all configured reports and its name by aircraft.
   *
   * Returns configured reports with name and number for a given aircraft
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSnapshotMasterdataReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSnapshotMasterdataReports(params, context) {
    return this.getSnapshotMasterdataReports$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function SnapshotMasterdataService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SnapshotMasterdataService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SnapshotMasterdataService,
      factory: SnapshotMasterdataService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SnapshotMasterdataService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function deleteSingleAssetUserPreference(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, deleteSingleAssetUserPreference.PATH, 'delete');
  if (params) {
    rb.header('app_id', params.app_id, {});
    rb.path('userId', params.userId, {});
    rb.path('applicationId', params.applicationId, {});
    rb.path('assetType', params.assetType, {});
    rb.path('assetId', params.assetId, {});
    rb.path('preferenceName', params.preferenceName, {});
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
deleteSingleAssetUserPreference.PATH = '/users/{userId}/apps/{applicationId}/assets/{assetType}/{assetId}/preferences/{preferenceName}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getAssetUserPreferences(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getAssetUserPreferences.PATH, 'get');
  if (params) {
    rb.header('app_id', params.app_id, {});
    rb.path('userId', params.userId, {});
    rb.path('applicationId', params.applicationId, {});
    rb.path('assetType', params.assetType, {});
    rb.path('assetId', params.assetId, {});
    rb.query('prefs', params.prefs, {
      "explode": true
    });
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getAssetUserPreferences.PATH = '/users/{userId}/apps/{applicationId}/assets/{assetType}/{assetId}/preferences';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getSingleAssetUserPreference(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getSingleAssetUserPreference.PATH, 'get');
  if (params) {
    rb.header('app_id', params.app_id, {});
    rb.path('userId', params.userId, {});
    rb.path('applicationId', params.applicationId, {});
    rb.path('assetType', params.assetType, {});
    rb.path('assetId', params.assetId, {});
    rb.path('preferenceName', params.preferenceName, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getSingleAssetUserPreference.PATH = '/users/{userId}/apps/{applicationId}/assets/{assetType}/{assetId}/preferences/{preferenceName}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function setSingleAssetUserPreference(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, setSingleAssetUserPreference.PATH, 'put');
  if (params) {
    rb.header('app_id', params.app_id, {});
    rb.header('ttl', params.ttl, {});
    rb.path('userId', params.userId, {});
    rb.path('applicationId', params.applicationId, {});
    rb.path('assetType', params.assetType, {});
    rb.path('assetId', params.assetId, {});
    rb.path('preferenceName', params.preferenceName, {});
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
setSingleAssetUserPreference.PATH = '/users/{userId}/apps/{applicationId}/assets/{assetType}/{assetId}/preferences/{preferenceName}';

/**
 * Manages asset specific user preferences.
 */
class AssetUserService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getAssetUserPreferences()` */
  static {
    this.GetAssetUserPreferencesPath = '/users/{userId}/apps/{applicationId}/assets/{assetType}/{assetId}/preferences';
  }
  /**
   * Returns a list of asset user preferences.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAssetUserPreferences()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssetUserPreferences$Response(params, context) {
    return getAssetUserPreferences(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns a list of asset user preferences.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAssetUserPreferences$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssetUserPreferences(params, context) {
    return this.getAssetUserPreferences$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getSingleAssetUserPreference()` */
  static {
    this.GetSingleAssetUserPreferencePath = '/users/{userId}/apps/{applicationId}/assets/{assetType}/{assetId}/preferences/{preferenceName}';
  }
  /**
   * Returns the asset user preference for the given name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleAssetUserPreference()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleAssetUserPreference$Response(params, context) {
    return getSingleAssetUserPreference(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns the asset user preference for the given name.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleAssetUserPreference$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleAssetUserPreference(params, context) {
    return this.getSingleAssetUserPreference$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `setSingleAssetUserPreference()` */
  static {
    this.SetSingleAssetUserPreferencePath = '/users/{userId}/apps/{applicationId}/assets/{assetType}/{assetId}/preferences/{preferenceName}';
  }
  /**
   * Updates an asset user preference.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setSingleAssetUserPreference()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSingleAssetUserPreference$Response(params, context) {
    return setSingleAssetUserPreference(this.http, this.rootUrl, params, context);
  }
  /**
   * Updates an asset user preference.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setSingleAssetUserPreference$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSingleAssetUserPreference(params, context) {
    return this.setSingleAssetUserPreference$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `deleteSingleAssetUserPreference()` */
  static {
    this.DeleteSingleAssetUserPreferencePath = '/users/{userId}/apps/{applicationId}/assets/{assetType}/{assetId}/preferences/{preferenceName}';
  }
  /**
   * Deletes an asset user preference.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSingleAssetUserPreference()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSingleAssetUserPreference$Response(params, context) {
    return deleteSingleAssetUserPreference(this.http, this.rootUrl, params, context);
  }
  /**
   * Deletes an asset user preference.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSingleAssetUserPreference$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSingleAssetUserPreference(params, context) {
    return this.deleteSingleAssetUserPreference$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function AssetUserService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AssetUserService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AssetUserService,
      factory: AssetUserService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AssetUserService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function deleteSingleUserPreference(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, deleteSingleUserPreference.PATH, 'delete');
  if (params) {
    rb.header('app_id', params.app_id, {});
    rb.path('userId', params.userId, {});
    rb.path('applicationId', params.applicationId, {});
    rb.path('preferenceName', params.preferenceName, {});
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
deleteSingleUserPreference.PATH = '/users/{userId}/apps/{applicationId}/preferences/{preferenceName}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getSingleUserPreference(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getSingleUserPreference.PATH, 'get');
  if (params) {
    rb.header('app_id', params.app_id, {});
    rb.path('userId', params.userId, {});
    rb.path('applicationId', params.applicationId, {});
    rb.path('preferenceName', params.preferenceName, {});
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getSingleUserPreference.PATH = '/users/{userId}/apps/{applicationId}/preferences/{preferenceName}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function getUserAppPreferences(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, getUserAppPreferences.PATH, 'get');
  if (params) {
    rb.header('app_id', params.app_id, {});
    rb.path('userId', params.userId, {});
    rb.path('applicationId', params.applicationId, {});
    rb.query('prefs', params.prefs, {
      "explode": true
    });
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
getUserAppPreferences.PATH = '/users/{userId}/apps/{applicationId}/preferences';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
function setSingleUserPreference(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, setSingleUserPreference.PATH, 'put');
  if (params) {
    rb.header('app_id', params.app_id, {});
    rb.path('userId', params.userId, {});
    rb.path('applicationId', params.applicationId, {});
    rb.path('preferenceName', params.preferenceName, {});
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
setSingleUserPreference.PATH = '/users/{userId}/apps/{applicationId}/preferences/{preferenceName}';

/**
 * Manage preferences and users
 */
class UserService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `getUserAppPreferences()` */
  static {
    this.GetUserAppPreferencesPath = '/users/{userId}/apps/{applicationId}/preferences';
  }
  /**
   * Returns an app-specific list of user preference objects.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAppPreferences()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAppPreferences$Response(params, context) {
    return getUserAppPreferences(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns an app-specific list of user preference objects.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserAppPreferences$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAppPreferences(params, context) {
    return this.getUserAppPreferences$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `getSingleUserPreference()` */
  static {
    this.GetSingleUserPreferencePath = '/users/{userId}/apps/{applicationId}/preferences/{preferenceName}';
  }
  /**
   * Returns the preference object for the given ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleUserPreference()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleUserPreference$Response(params, context) {
    return getSingleUserPreference(this.http, this.rootUrl, params, context);
  }
  /**
   * Returns the preference object for the given ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleUserPreference$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleUserPreference(params, context) {
    return this.getSingleUserPreference$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `setSingleUserPreference()` */
  static {
    this.SetSingleUserPreferencePath = '/users/{userId}/apps/{applicationId}/preferences/{preferenceName}';
  }
  /**
   * Update a single preference.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setSingleUserPreference()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSingleUserPreference$Response(params, context) {
    return setSingleUserPreference(this.http, this.rootUrl, params, context);
  }
  /**
   * Update a single preference.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setSingleUserPreference$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSingleUserPreference(params, context) {
    return this.setSingleUserPreference$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `deleteSingleUserPreference()` */
  static {
    this.DeleteSingleUserPreferencePath = '/users/{userId}/apps/{applicationId}/preferences/{preferenceName}';
  }
  /**
   * Delete a single preference.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSingleUserPreference()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSingleUserPreference$Response(params, context) {
    return deleteSingleUserPreference(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete a single preference.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSingleUserPreference$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSingleUserPreference(params, context) {
    return this.deleteSingleUserPreference$Response(params, context).pipe(map(r => r.body));
  }
  static {
    this.ɵfac = function UserService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UserService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserService,
      factory: UserService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
/**
 * Module that provides all services and configuration.
 */
class AviatarApiModule {
  static forRoot(params) {
    return {
      ngModule: AviatarApiModule,
      providers: [{
        provide: AviatarApiConfiguration,
        useValue: params
      }]
    };
  }
  constructor(parentModule, http) {
    if (parentModule) {
      throw new Error('AviatarApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
  static {
    this.ɵfac = function AviatarApiModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AviatarApiModule)(i0.ɵɵinject(AviatarApiModule, 12), i0.ɵɵinject(i2.HttpClient, 8));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AviatarApiModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [AircraftService, AircraftDetailProviderService, AircraftLockingService, AircraftOperatorService, LivePositionService, FlightsService, CurrentStatusService, AirportsService, AmosCentralRequestInfoServiceService, AmosCentralRequestPdfServiceService, ApplicationService, ComponentsServiceV2Service, TagsService, CurrentAlertsService, ExtensionComponentService, FaultMasterDataApiV2MasterDataService, FaultMasterDataApiV2PrioritiesService, FaultsApIsService, FrontendFragmentService, ApnsSubscriptionService, UserCategoryService, InboxService, WebPushSubscriptionService, PartnerService, WorkOrderServiceV2Service, PermissionService, ProcessService, ReleaseNotesAttachmentsService, ReleaseNotesService, RemarksService, SubjectService, TelexUplinkService, AcmsService, SnapshotApiService, SnapshotMasterdataService, AssetUserService, UserService, AviatarApiConfiguration]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AviatarApiModule, [{
    type: NgModule,
    args: [{
      imports: [],
      exports: [],
      declarations: [],
      providers: [AircraftService, AircraftDetailProviderService, AircraftLockingService, AircraftOperatorService, LivePositionService, FlightsService, CurrentStatusService, AirportsService, AmosCentralRequestInfoServiceService, AmosCentralRequestPdfServiceService, ApplicationService, ComponentsServiceV2Service, TagsService, CurrentAlertsService, ExtensionComponentService, FaultMasterDataApiV2MasterDataService, FaultMasterDataApiV2PrioritiesService, FaultsApIsService, FrontendFragmentService, ApnsSubscriptionService, UserCategoryService, InboxService, WebPushSubscriptionService, PartnerService, WorkOrderServiceV2Service, PermissionService, ProcessService, ReleaseNotesAttachmentsService, ReleaseNotesService, RemarksService, SubjectService, TelexUplinkService, AcmsService, SnapshotApiService, SnapshotMasterdataService, AssetUserService, UserService, AviatarApiConfiguration]
    }]
  }], () => [{
    type: AviatarApiModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }, {
    type: i2.HttpClient,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
var FaultMasterDataApiV2MessageMasterDataType;
(function (FaultMasterDataApiV2MessageMasterDataType) {
  FaultMasterDataApiV2MessageMasterDataType["Valid"] = "VALID";
  FaultMasterDataApiV2MessageMasterDataType["Corrupted"] = "CORRUPTED";
})(FaultMasterDataApiV2MessageMasterDataType || (FaultMasterDataApiV2MessageMasterDataType = {}));

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
var FaultMasterDataApiV2MessageType;
(function (FaultMasterDataApiV2MessageType) {
  FaultMasterDataApiV2MessageType["Corrupted"] = "CORRUPTED";
  FaultMasterDataApiV2MessageType["CorruptedMessage"] = "CORRUPTED_MESSAGE";
  FaultMasterDataApiV2MessageType["EmptyReport"] = "EMPTY_REPORT";
  FaultMasterDataApiV2MessageType["FaultMessage"] = "FAULT_MESSAGE";
  FaultMasterDataApiV2MessageType["FlightDeckEffect"] = "FLIGHT_DECK_EFFECT";
  FaultMasterDataApiV2MessageType["FlightEffect"] = "FLIGHT_EFFECT";
  FaultMasterDataApiV2MessageType["MaintenanceMessage"] = "MAINTENANCE_MESSAGE";
  FaultMasterDataApiV2MessageType["OkMessage"] = "OK_MESSAGE";
  FaultMasterDataApiV2MessageType["WarningMessage"] = "WARNING_MESSAGE";
  FaultMasterDataApiV2MessageType["Unknown"] = "UNKNOWN";
})(FaultMasterDataApiV2MessageType || (FaultMasterDataApiV2MessageType = {}));

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
var FaultMasterDataApiV2Priority;
(function (FaultMasterDataApiV2Priority) {
  FaultMasterDataApiV2Priority["None"] = "NONE";
  FaultMasterDataApiV2Priority["Low"] = "LOW";
  FaultMasterDataApiV2Priority["Medium"] = "MEDIUM";
  FaultMasterDataApiV2Priority["High"] = "HIGH";
  FaultMasterDataApiV2Priority["Ok"] = "OK";
  FaultMasterDataApiV2Priority["Nuisance"] = "NUISANCE";
  FaultMasterDataApiV2Priority["Undefined"] = "UNDEFINED";
})(FaultMasterDataApiV2Priority || (FaultMasterDataApiV2Priority = {}));

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
/**
 * Message delivery channel.
 */
var MessageChannel;
(function (MessageChannel) {
  MessageChannel["Apns"] = "apns";
  MessageChannel["Email"] = "email";
  MessageChannel["Inbox"] = "inbox";
  MessageChannel["Webpush"] = "webpush";
})(MessageChannel || (MessageChannel = {}));

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

/*
 * Public API Surface of api-client
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AVIATAR_API_CONFIG, AcmsService, AircraftDetailProviderService, AircraftLockingService, AircraftOperatorService, AircraftService, AirportsService, AmosCentralRequestInfoServiceService, AmosCentralRequestPdfServiceService, ApiCredentialsInterceptor, ApnsSubscriptionService, ApplicationService, AssetUserService, AviatarApiClientModule, AviatarApiConfigClass, AviatarApiConfiguration, AviatarApiModule, ComponentsServiceV2Service, CurrentAlertsService, CurrentStatusService, ExtensionComponentService, FaultMasterDataApiV2MasterDataService, FaultMasterDataApiV2MessageMasterDataType, FaultMasterDataApiV2MessageType, FaultMasterDataApiV2PrioritiesService, FaultMasterDataApiV2Priority, FaultsApIsService, FlightsService, FrontendFragmentService, InboxService, LivePositionService, MessageChannel, PartnerService, PermissionService, ProcessService, ReleaseNotesAttachmentsService, ReleaseNotesService, RemarksService, SnapshotApiService, SnapshotMasterdataService, SubjectService, TagsService, TelexUplinkService, UserCategoryService, UserService, WebPushSubscriptionService, WorkOrderServiceV2Service };
